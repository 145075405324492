import * as yup from "yup";

export const schema = yup
  .object({
    parameter_group_ndx: yup.number().nullable(),
    parameter_group_desc: yup.string().required(),
    notes: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
  })
  .required();

export const DEFAULT_PARAMETER_GROUP_INFO_STATE = {
  parameter_group_ndx: null,
  parameter_group_desc: "",
  notes: "",
};

import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useFormContext } from "react-hook-form";

import {
  AssocFormSection,
  FormSection,
  GridItem,
  SimpleTextField,
  SimpleSelect,
  RelationPicker,
  SimpleCheckbox,
} from "../BaseComponents";

function ParameterInfoForm({ lookups }) {
  const { setValue, watch } = useFormContext();

  const watchParameterGroups = watch("assoc_parameter_group_ndx");

  const handleAddParameterGroups = (values) => {
    setValue("assoc_parameter_group_ndx", values, { shouldDirty: true });
  };

  const handleRemoveParameterGroups = (value) => {
    const newValues = watchParameterGroups.filter((item) => item !== value);
    setValue("assoc_parameter_group_ndx", newValues, { shouldDirty: true });
  };

  return (
    <>
      <FormSection>
        <Typography variant="h5">Basic Info</Typography>

        <Grid container spacing={4}>
          <GridItem>
            <SimpleTextField name="parameter_code" label="Parameter Code" />
          </GridItem>
          <GridItem>
            <SimpleTextField
              name="parameter_name"
              label="Parameter Name"
              required
            />
          </GridItem>
          <GridItem>
            <SimpleTextField
              name="param_speciation_text"
              label="Parameter Speciation"
            />
          </GridItem>
        </Grid>
        <Grid container spacing={4}>
          <GridItem>
            <SimpleSelect
              name="parameter_type_ndx"
              label="Parameter Type"
              displayField="parameter_type_desc"
              valueField="parameter_type_ndx"
              options={lookups?.parameterTypes ?? []}
              required
            />
          </GridItem>
          <GridItem>
            <SimpleSelect
              name="param_units"
              label="Parameter Units"
              displayField="unit_desc_official"
              valueField="unit_ndx"
              options={lookups?.units ?? []}
            />
          </GridItem>
        </Grid>
        <Grid container spacing={4}>
          <GridItem>
            <SimpleTextField name="import_val_code" label="Import Value Code" />
          </GridItem>
          <GridItem>
            <SimpleTextField name="import_flag_code" label="Import Flag Code" />
          </GridItem>
        </Grid>
      </FormSection>

      <FormSection>
        <Typography variant="h5">Configuration Options</Typography>
        <Grid container spacing={4}>
          <Grid item>
            <SimpleCheckbox name="active" label="Active" />
          </Grid>
          <Grid item>
            <SimpleCheckbox
              name="wellwatch_param"
              label="Well Watch Parameter"
            />
          </Grid>
          <Grid item>
            <SimpleCheckbox name="low_is_bad" label="Low is Bad" />
          </Grid>
          <Grid item>
            <SimpleCheckbox name="analysis_include" label="Analysis Include" />
          </Grid>
          <Grid item>
            <SimpleCheckbox name="core_parameter" label="Core Parameter" />
          </Grid>
          <Grid item>
            <SimpleCheckbox name="map_parameter" label="Map Parameter" />
          </Grid>
        </Grid>
      </FormSection>

      <Grid container spacing={4}>
        <Grid item md={6}>
          <AssocFormSection>
            <Typography variant="h5">Parameter Groups</Typography>
            <RelationPicker
              onAdd={handleAddParameterGroups}
              onRemove={handleRemoveParameterGroups}
              options={lookups.parameterGroups}
              displayField="parameter_group_desc"
              valueField="parameter_group_ndx"
              value={watchParameterGroups || []}
              urlPrefix={"/data-management/parameter-groups"}
            />
          </AssocFormSection>
        </Grid>
      </Grid>
    </>
  );
}

export default ParameterInfoForm;

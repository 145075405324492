import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { NavLink, useParams } from "react-router-dom";

import ParameterGroupInfo from "../ParameterGroupInfo";
import { useStateIfMounted } from "use-state-if-mounted";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

function ParameterGroupsManagement() {
  let { id } = useParams();
  const [selectedRecord, setSelectedRecord] = useStateIfMounted(id);

  const [triggerRefetch, setTriggerRefetch] = React.useState(false);
  const handleTriggerRefetch = () => setTriggerRefetch(!triggerRefetch);
  const handleRefetch = () => {
    handleTriggerRefetch();
  };

  return (
    <React.Fragment>
      <Helmet title="Parameter Setup" />
      <Typography variant="h3" gutterBottom display="inline">
        Parameter Group Setup
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Link component={NavLink} exact to="/data-management/parameter-groups">
          Parameter Groups Management
        </Link>
        <Typography>Edit Parameter Group</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <ParameterGroupInfo
        selectedRecord={selectedRecord}
        setSelectedRecord={setSelectedRecord}
        isEditMode={true}
        refetchPermits={handleRefetch}
      />
    </React.Fragment>
  );
}

export default ParameterGroupsManagement;

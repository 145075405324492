import {
  Accordion,
  AccordionActions,
  AccordionDetails as MuiAccordingDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Button,
  Divider,
  Paper,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import styled from "styled-components/macro";

import Loader from "../../../components/Loader";
import { schema, DEFAULT_UNIT_INFO_STATE } from "./constants";
import { useSubmitData } from "../../../hooks/useSubmitData";
import { useUnitInfo } from "./useUnitInfo";

import { CircularProgress } from "@material-ui/core";
import UnitInfoForm from "./UnitInfoForm";
import { useForm, FormProvider } from "react-hook-form";

const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 0 16px;
`;

const AccordionDetails = styled(MuiAccordingDetails)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px;
`;

export const getButtonIcon = (isFormSubmitting) => {
  if (isFormSubmitting) {
    return <CircularProgress color="inherit" size={20} />;
  }
};

export const getButtonText = (isFormSubmitting, isNewWell = false) => {
  if (isFormSubmitting) {
    if (isNewWell) {
      return "Creating";
    } else {
      return "Saving";
    }
  }
  if (isNewWell) {
    return "Create";
  } else {
    return "Save";
  }
};

function UnitInfo({
  selectedRecord,
  setSelectedRecord,
  refetchUnits = () => {},
  isEditMode = false,
}) {
  const { data, isLoading, error, refetch } = useUnitInfo(selectedRecord);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_UNIT_INFO_STATE,
    values: {
      unit_ndx: data?.unit_ndx || DEFAULT_UNIT_INFO_STATE.unit_ndx,
      unit_desc_official:
        data?.unit_desc_official || DEFAULT_UNIT_INFO_STATE.unit_desc_official,
    },
  });

  const isDirty = methods.formState.isDirty;
  const isValid = methods.formState.isValid;

  const handleRefreshAfterSubmit = () => {
    refetchUnits();
  };

  const { handleSubmit: onSubmit, isFormSubmitting } = useSubmitData(
    selectedRecord,
    setSelectedRecord,
    data,
    "list-units",
    "unit_ndx",
    refetch,
    handleRefreshAfterSubmit
  );

  const handleReset = () => {
    methods.reset(data);
  };

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !data?.unit_ndx && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !data?.unit_ndx) ? "none" : "block",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box width="100%">
            <Typography variant="h3">Unit Information</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <FormProvider {...methods}>
            <form id="unit-info-form" onSubmit={methods.handleSubmit(onSubmit)}>
              <UnitInfoForm />
            </form>
          </FormProvider>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button size="small" onClick={handleReset}>
            Reset
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            type="submit"
            form="unit-info-form"
            disabled={!isDirty || (isDirty && !isValid) || isFormSubmitting}
            startIcon={getButtonIcon(isFormSubmitting)}
            style={{ width: "100px" }}
          >
            {getButtonText(isFormSubmitting, selectedRecord === 0)}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  );
}

export default UnitInfo;

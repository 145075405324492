import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStateIfMounted } from "use-state-if-mounted";
import { useCreateLookupForTable } from "../../../hooks/useCreateLookupForTable";

export const useOwners = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedRecord, setSelectedRecord] = useStateIfMounted(null);

  const { data, isFetching, error, refetch } = useQuery(
    ["list-owners"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-owners`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const ownerTypesLookup = useCreateLookupForTable({
    endpoint: "list-owner-types",
    valueField: "owner_type_ndx",
    labelField: "owner_type_desc",
  });

  const columns = [
    {
      title: "Owner Id",
      field: "owner_id",
    },
    {
      title: "First Name",
      field: "first_name",
    },
    {
      title: "Last Name",
      field: "last_name",
    },
    {
      title: "Owner Type",
      field: "owner_type_ndx",
      lookup: ownerTypesLookup,
    },
    {
      title: "Address 1",
      field: "address1",
    },
    {
      title: "Address 2",
      field: "address2",
    },
    {
      title: "City",
      field: "city",
    },
    {
      title: "State",
      field: "state",
    },
    {
      title: "Zip",
      field: "zipcode",
    },
    {
      title: "Phone",
      field: "phone_no",
    },
  ];

  return {
    selectedRecord,
    setSelectedRecord,
    data,
    isFetching,
    error,
    refetch,
    columns,
  };
};

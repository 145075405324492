import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "../../../../../components/inputs";
import { useForm } from "react-hook-form";
import {
  Box,
  CircularProgress,
  Typography as MuiTypography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useApp } from "../../../../../AppProvider";
import { useMutation } from "react-query";

const Typography = styled(MuiTypography)(spacing);

const PendingRecordsContainer = styled.div`
  margin-bottom: 16px;
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled(Typography)`
  margin-right: 32px;
  margin-bottom: 8px;
  margin-top: 32px;
  width: 200px;
`;

const EmptyState = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 16px;
  margin-bottom: 0;
  padding: 32px;
  text-align: center;
  width: 100%;
`;

const PendingRecordFormRow = ({ control, index, verifiedOptions }) => {
  if (!verifiedOptions?.length) return null;
  return (
    <Box display="flex" mt={4}>
      <TextField
        control={control}
        InputProps={{
          readOnly: true,
        }}
        name={`${index}.pending_name`}
        label={"Pending Value"}
        size="small"
        variant={"outlined"}
        style={{
          marginRight: "32px",
          width: 200,
        }}
      />
      <Autocomplete
        control={control}
        name={`${index}.verified_ndx`}
        label={"Verified Value"}
        options={verifiedOptions?.map((d) => [d.value, d.label])}
        variant={"outlined"}
        size="small"
        rules={{ required: "Verified value is required" }}
      />
    </Box>
  );
};

const PendingRecordsTable = ({
  data,
  name,
  handlePoll,
  submitEndpoint,
  verifiedOptions,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { doToast } = useApp();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm();

  useEffect(() => {
    if (!data?.length || !verifiedOptions?.length) return;

    // see https://codesandbox.io/p/sandbox/react-hook-from-async-set-form-values-tyk12?file=/src/index.js
    reset(
      data?.reduce((acc, curr, index) => {
        acc[index] = curr;
        return acc;
      }, {})
    );
  }, [reset, data, verifiedOptions]);

  const { mutateAsync } = useMutation(
    async (formData) => {
      setIsLoading(true);

      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };

      await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/api/${submitEndpoint}`,
        {
          data: Object.values(formData),
        },
        { headers }
      );
      doToast(
        "info",
        "Records processing: This may take up to a minute to complete",
        { persist: true }
      );
    },
    {
      onSuccess: async () => {
        await handlePoll();
        doToast("success", "Pending records were saved to the database", {
          persist: true,
        });
      },
      onError: (error) => {
        console.error(error);
        doToast("error", error.message);
      },
      onSettled: () => {
        setIsLoading(false);
      },
    }
  );

  const onSubmit = async (formData) => {
    await mutateAsync(formData);
  };

  if (!data) return null;
  return (
    <form onSubmit={handleSubmit(onSubmit)} method={"post"}>
      {data?.length === 0 ? (
        <EmptyState>
          <Typography variant="body1">No pending records</Typography>
        </EmptyState>
      ) : (
        <>
          <HeaderRow>
            <Header variant="h6" mb={4}>
              Pending Value
            </Header>
            <Header variant="h6" mb={4}>
              Verified Value
            </Header>
          </HeaderRow>
          <PendingRecordsContainer>
            {data.map((d, i) => (
              <PendingRecordFormRow
                key={`${name}-${d.pending_ndx}`}
                index={i}
                control={control}
                verifiedOptions={verifiedOptions}
              />
            ))}
          </PendingRecordsContainer>
          <Button
            disabled={!isValid || isLoading}
            startIcon={
              isLoading && <CircularProgress m={2} size={16} color="primary" />
            }
            variant={"contained"}
            color={"primary"}
            type={"submit"}
          >
            {isLoading ? "Submitting" : "Submit"}
          </Button>
        </>
      )}
    </form>
  );
};

export default PendingRecordsTable;

import { Controller } from "react-hook-form";
import { Select as MuiSelect } from "@material-ui/core";

export const Select = (props) => {
  const { control, name, ...rest } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <MuiSelect {...rest} {...field} />}
    />
  );
};

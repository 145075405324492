import { FormProvider, useForm } from "react-hook-form";
import { Grid } from "@material-ui/core";
import { SimpleTextField } from "./FormInputs";
import Button from "@material-ui/core/Button";
import React from "react";

export const START_DATE = new Date(
  new Date().setFullYear(new Date().getFullYear() - 1)
)
  .toISOString()
  .split("T")[0];

export const END_DATE = new Date().toISOString().split("T")[0];

const RecordDateFilterControl = ({ onSubmit }) => {
  const methods = useForm({
    values: {
      start_date: START_DATE,
      end_date: END_DATE,
    },
    defaultValues: {
      start_date: START_DATE,
      end_date: END_DATE,
    },
  });

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} method={"post"}>
      <FormProvider {...methods}>
        <Grid container spacing={4} style={{ marginBottom: "16px" }}>
          <Grid item xs={12} md={2}>
            <SimpleTextField
              name="start_date"
              label="Start Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SimpleTextField
              name="end_date"
              label="End Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Button
          disabled={!methods.formState.isValid}
          variant={"contained"}
          color={"primary"}
          type={"submit"}
        >
          Submit
        </Button>
      </FormProvider>
    </form>
  );
};

export default RecordDateFilterControl;

/* eslint-disable import/first */
import React from "react";
import { CRUD_MODELS, ROUTES } from "../constants";
import { useAuth0 } from "@auth0/auth0-react";

import async from "../components/Async";

import {
  Database,
  FileText,
  Home,
  Monitor,
  Users,
  Map as MapIcon,
  Share2,
  AlertCircle,
  Trash,
} from "react-feather";

import Landing from "../pages/presentation/Landing";
import * as inflector from "inflected";
import { CrudProvider } from "../CrudProvider";

// TODO MAYBE LAZY IMPORT
import WaterQualityMap from "../pages/waterQualityMap";
import DashboardHome from "../pages/dashboards/home";
import AdminGuard from "../components/AdminGuard";
import UserGuard from "../components/UserGuard";
import AdminVisibilityFilter from "../components/AdminVisibilityFilter";
import Structures from "../pages/dataManagement/Structures";
import EditStructure from "../pages/dataManagement/Structures/[id]";
import StructureGroups from "../pages/dataManagement/StructureGroups";
import EditStructureGroup from "../pages/dataManagement/StructureGroups/[id]";
import Owners from "../pages/dataManagement/Owners";
import EditOwner from "../pages/dataManagement/Owners/[id]";
import Units from "../pages/dataManagement/Units";
import Methods from "../pages/dataManagement/Methods";
import Parameters from "../pages/dataManagement/Parameters";
import EditParameter from "../pages/dataManagement/Parameters/[id]";
import ParameterGroups from "../pages/dataManagement/ParameterGroups";
import EditParameterGroup from "../pages/dataManagement/ParameterGroups/[id]";
import OwnerGroups from "../pages/dataManagement/OwnerGroups";
import EditOwnerGroup from "../pages/dataManagement/OwnerGroups/[id]";
import UsersToStructures from "../pages/dataManagement/Associations";
import BasicImporter from "../pages/dataManagement/Importers/Basic";
import ContinuousImporter from "../pages/dataManagement/Importers/Continuous";
import BasicPendingRecords from "../pages/dataManagement/PendingRecords/Basic";
import BasicDeleteRecords from "../pages/dataManagement/DeleteRecords/Basic";
import ContinuousDeleteRecords from "../pages/dataManagement/DeleteRecords/Continuous";
import BasicDownloadRecords from "../pages/download/basic/QueryAndDownload";
import RawDownloadRecords from "../pages/download/raw/QueryAndDownload";
import ContinuousDownloadRecords from "../pages/download/continuous/QueryAndDownload";
import UserVisibilityFilter from "../components/UserVisibilityFilter";

const Account = async(() => import("../pages/pages/Account"));
const Profile = async(() => import("../pages/pages/Profile"));

const CrudIndexPage = async(() => import("../components/crud/CrudIndexPage"));
const CrudViewPage = async(() => import("../components/crud/CrudViewPage"));

const getSidebarMenu = (list) => {
  return list.map((item) => {
    const slug = inflector.dasherize(inflector.underscore(item.name));
    return {
      id: item.sidebarName ?? inflector.titleize(item.name),
      path: `/models/${slug}`,
      model: inflector.singularize(item.name),
      icon: item.icon || <Database />,
      component: CrudIndexPage,
      config: require(`../pages/models/${item.name}Config`),
      provider: CrudProvider,
      children: item.children,
      header: item.header,
      guard: item.guard,
      visibilityFilter: item.visibilityFilter,
    };
  });
};

const getCrudRoutes = (list) => {
  return list.map((item) => {
    const config = require(`../pages/models/${item.name}Config`);
    const slug = inflector.dasherize(inflector.underscore(item.name));

    return {
      id: inflector.titleize(item.name),
      path: `/models/${slug}`,
      model: inflector.singularize(item.name),
      component: CrudIndexPage,
      provider: CrudProvider,
      config,
      crud: [
        {
          path: `/models/${slug}/:id`,
          name: `View ${inflector.titleize(inflector.singularize(item.name))}`,
          component: CrudViewPage,
          provider: CrudProvider,
          model: inflector.singularize(item.name),
          config,
        },
        {
          path: `/models/${slug}/add`,
          name: `Add ${inflector.titleize(inflector.singularize(item.name))}`,
          component: CrudViewPage,
          provider: CrudProvider,
          model: inflector.singularize(item.name),
          config,
        },
      ],
    };
  });
};

const crudSidebarMenu = [...getSidebarMenu(CRUD_MODELS)];
const modelCrudRoutes = [...getCrudRoutes(CRUD_MODELS)];

const mapRoutes = {
  header: "Data Explorer",
  id: "Map Explorer",
  icon: <MapIcon />,
  path: "/map-explorer",
  name: "Map Explore",
  component: WaterQualityMap,
  guard: UserGuard,
  visibilityFilter: UserVisibilityFilter,
};

const queryRoutes = {
  id: "Query & Download",
  name: "Query & Download",
  icon: <FileText />,
  children: [
    {
      path: "/download/basic",
      name: "Daily Averages",
      component: BasicDownloadRecords,
      guard: UserGuard,
      visibilityFilter: UserVisibilityFilter,
    },
    {
      path: "/download/raw",
      name: "Raw",
      component: RawDownloadRecords,
      guard: UserGuard,
      visibilityFilter: UserVisibilityFilter,
    },
    {
      path: "/download/continuous",
      name: "Continuous",
      component: ContinuousDownloadRecords,
      guard: UserGuard,
      visibilityFilter: UserVisibilityFilter,
    },
  ],
  guard: UserGuard,
  visibilityFilter: UserVisibilityFilter,
};

const dataManagementRoutes = {
  header: "Data Management",
  id: "Tables",
  icon: <Database />,
  children: [
    {
      path: "/data-management/structures",
      name: "Structures",
      component: Structures,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/structures/:id",
      name: "Edit Structure",
      component: EditStructure,
      guard: AdminGuard,
      visibilityFilter: () => false,
    },
    {
      path: "/data-management/structure-groups",
      name: "Structure Groups",
      component: StructureGroups,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/structure-groups/:id",
      name: "Edit Structure Group",
      component: EditStructureGroup,
      guard: AdminGuard,
      visibilityFilter: () => false,
    },
    {
      path: "/data-management/owners",
      name: "Owners",
      component: Owners,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/owners/:id",
      name: "Edit Owner",
      component: EditOwner,
      guard: AdminGuard,
      visibilityFilter: () => false,
    },
    {
      path: "/data-management/owner-groups",
      name: "Owner Groups",
      component: OwnerGroups,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/owner-groups/:id",
      name: "Edit Owner Group",
      component: EditOwnerGroup,
      guard: AdminGuard,
      visibilityFilter: () => false,
    },
    {
      path: "/data-management/units",
      name: "Units",
      component: Units,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/methods",
      name: "Methods",
      component: Methods,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/parameters",
      name: "Parameters",
      component: Parameters,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/parameters/:id",
      name: "Edit Parameter",
      component: EditParameter,
      guard: AdminGuard,
      visibilityFilter: () => false,
    },
    {
      path: "/data-management/parameter-groups",
      name: "Parameter Groups",
      component: ParameterGroups,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/parameter-groups/:id",
      name: "Edit Parameter Group",
      component: EditParameterGroup,
      guard: AdminGuard,
      visibilityFilter: () => false,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const associationsRoutes = {
  id: "Associations",
  icon: <Share2 />,
  children: [
    {
      path: "/data-management/users-to-structures",
      name: "Users to Structures",
      component: UsersToStructures,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const dataImportRoutes = {
  id: "Importers",
  icon: <Database />,
  children: [
    {
      path: "/data-management/importers/basic",
      name: "Import Water Quality Data",
      component: BasicImporter,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/importers/continuous",
      name: "Import Continuous Water Quality Data",
      component: ContinuousImporter,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const pendingRecordsRoutes = {
  id: "Pending Records",
  icon: <AlertCircle />,
  path: "/data-management/pending-records",
  name: "Pending Records",
  component: BasicPendingRecords,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const deleteRecordsRoutes = {
  id: "Delete Records",
  icon: <Trash />,
  children: [
    {
      path: "/data-management/delete-records/basic",
      name: "Delete Records",
      component: BasicDeleteRecords,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/delete-records/continuous",
      name: "Delete Continuous Records",
      component: ContinuousDeleteRecords,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const accountRoutes = {
  id: "Account",
  path: "/account",
  name: "Account",
  header: "Pages",
  icon: <Users />,
  component: Account,
  children: [
    {
      path: ROUTES.USER_PROFILE,
      name: "Profile",
      component: Profile,
    },
    {
      path: "/auth/logout",
      name: "Logout",
      component: function Logout() {
        const { logout } = useAuth0();
        logout();
      },
    },
  ],
};

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

const mainRoutes = {
  header: "Home",
  id: "Home",
  path: "/dashboard",
  icon: <Home />,
  component: DashboardHome,
  children: null,
  containsHome: true,
  guard: UserGuard,
  visibilityFilter: UserVisibilityFilter,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  mainRoutes,
  dataManagementRoutes,
  associationsRoutes,
  dataImportRoutes,
  pendingRecordsRoutes,
  deleteRecordsRoutes,
  queryRoutes,
  accountRoutes,
];

export const dashboardMaxContentLayoutRoutes = [
  ...crudSidebarMenu,
  ...modelCrudRoutes,
  mapRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [accountRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes using the full screen map layout
export const fullscreenMapRoutes = [];

// Routes visible in the sidebar
export const sidebarRoutes = [
  ...crudSidebarMenu,
  mainRoutes,
  dataManagementRoutes,
  associationsRoutes,
  dataImportRoutes,
  pendingRecordsRoutes,
  deleteRecordsRoutes,
  mapRoutes,
  queryRoutes,
];

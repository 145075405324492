import React from "react";

import styled from "styled-components/macro";

import AppBar from "./AppBar";
import Hero from "./Hero";
import CallToAction from "./CallToAction";
import Footer from "../../../components/Footer";

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 470px);
`;

const CtaContainer = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  flex-grow: 1;
`;

function Presentation() {
  return (
    <React.Fragment>
      <AppBar />
      <Hero />
      <MainContent>
        <CtaContainer>
          <CallToAction />
        </CtaContainer>
        <Footer />
      </MainContent>
    </React.Fragment>
  );
}

export default Presentation;

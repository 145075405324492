import React from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  CardContent,
  Card as MuiCard,
  CardHeader,
  CardMedia,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { NavLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { customSecondary } from "../../../theme/variants";

const Typography = styled(MuiTypography)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 250px;
`;

const WelcomeHeader = styled(Typography)`
  font-weight: 400;
  text-transform: none;
`;

const WelcomeText = styled(Typography)`
  font-size: 1.25rem;
  margin-bottom: 2rem;
  max-width: 768px;
`;

const StoryCard = styled(Card)`
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.35) !important;

  &:hover {
    outline: 4px solid ${() => customSecondary[500]};
    & .title-highlight {
      color: ${() => customSecondary[500]};
    }
  }
`;

function DashboardHome() {
  return (
    <React.Fragment>
      <Helmet title="CCWCD Water Quality Dashboard Home" />
      <WelcomeHeader variant={"h2"} component={"h1"} gutterBottom>
        Welcome to the CCWCD Water Quality Dashboard!
      </WelcomeHeader>
      <WelcomeText variant={"body1"}>
        Use the Map Explorer to analyze water quality data collected by CCWCD or
        download data directly using the Query and Download tool.
      </WelcomeText>

      <Grid container spacing={6}>
        <Grid item xs={12} lg={6} xl={4}>
          <Link component={NavLink} to="/map-explorer" underline="none">
            <StoryCard mb={3}>
              <CardHeader
                titleTypographyProps={{ variant: "h3" }}
                className="title-highlight"
                title="Map Explorer"
              />
              <CardContent>
                <ChartWrapper>
                  <CardMedia
                    style={{ height: "100%" }}
                    image="/static/img/map_explorer.png"
                    title="Map Explorer"
                  />
                </ChartWrapper>
              </CardContent>
            </StoryCard>
          </Link>
        </Grid>
        <Grid item xs={12} lg={6} xl={4}>
          <Link component={NavLink} to="/download/basic" underline="none">
            <StoryCard mb={3}>
              <CardHeader
                titleTypographyProps={{ variant: "h3" }}
                className="title-highlight"
                title="Query & Download"
              />
              <CardContent>
                <ChartWrapper>
                  <CardMedia
                    style={{ height: "100%" }}
                    image="/static/img/query_and_download.png"
                    title="Query & Download"
                  />
                </ChartWrapper>
              </CardContent>
            </StoryCard>
          </Link>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default DashboardHome;

import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStateIfMounted } from "use-state-if-mounted";
import { useCreateLookupForTable } from "../../../hooks/useCreateLookupForTable";

export const useParameters = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedRecord, setSelectedRecord] = useStateIfMounted(null);

  const { data, isFetching, error, refetch } = useQuery(
    ["list-parameters"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-parameters`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const unitsLookup = useCreateLookupForTable({
    endpoint: "list-units",
    valueField: "unit_ndx",
    labelField: "unit_desc_official",
  });

  const parameterTypesLookup = useCreateLookupForTable({
    endpoint: "list-parameter-types",
    valueField: "parameter_type_ndx",
    labelField: "parameter_type_desc",
  });

  const columns = [
    {
      title: "Parameter Id",
      field: "parameter_ndx",
    },
    {
      title: "Parameter Code",
      field: "parameter_code",
    },
    {
      title: "Parameter Name",
      field: "parameter_name",
    },
    {
      title: "Parameter Speciation",
      field: "param_speciation_text",
    },
    {
      title: "Parameter Type",
      field: "parameter_type_ndx",
      lookup: parameterTypesLookup,
    },
    {
      title: "Units",
      field: "param_units",
      lookup: unitsLookup,
    },

    {
      title: "Import Value Code",
      field: "import_val_code",
    },
    {
      title: "Import Flag Code",
      field: "import_flag_code",
    },
    {
      title: "Active",
      field: "active",
      type: "boolean",
    },
    {
      title: "Well Watch Parameter",
      field: "wellwatch_param",
      type: "boolean",
    },
    {
      title: "Low Is Bad",
      field: "low_is_bad",
      type: "boolean",
    },
    {
      title: "Analysis Include",
      field: "analysis_include",
      type: "boolean",
    },
    {
      title: "Core Parameter",
      field: "core_param",
      type: "boolean",
    },
    {
      title: "Map Parameter",
      field: "map_param",
      type: "boolean",
    },
  ];

  return {
    selectedRecord,
    setSelectedRecord,
    data,
    isFetching,
    error,
    refetch,
    columns,
  };
};

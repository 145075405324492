import * as yup from "yup";

export const SCHEMA = yup
  .object({
    parameterGroup: yup.number().required(),
    parameters: yup.array().of(yup.number()).nullable(),
    parameterSearch: yup.string().nullable(),
    structureGroup: yup.number().required(),
    structures: yup.array().of(yup.number()).nullable(),
    structureSearch: yup.string().nullable(),
    startDate: yup.date().nullable(),
    endDate: yup.date().nullable(),
  })
  .required();

export const DEFAULT_FILTER_VALUES = {
  parameterGroup: 1,
  parameters: [],
  parameterSearch: "",
  structureGroup: 1,
  structures: [],
  structureSearch: "",
  startDate: null,
  endDate: null,
  recordCount: null,
};

export const CSV_DATE_TIME_FORMAT = "MMM dd yyyy, hh:mm a";
export const DATE_PICKER_FORMAT = "MM/dd/yyyy";

export const FILENAME = "raw-data.csv";

export const EXCLUDE_FIELDS = ["user_uuid"];

export const DEFAULT_QUERY_OPTIONS = {
  keepPreviousData: true,
  refetchOnWindowFocus: false,
};

export const BASE_URL = `${process.env.REACT_APP_ENDPOINT}/api`;

import { Grid, Typography } from "@material-ui/core";
import React from "react";

import { useFormContext } from "react-hook-form";

import {
  AssocFormSection,
  FormSection,
  GridItem,
  SimpleTextField,
  SimpleSelect,
  RelationPicker,
} from "../BaseComponents";

function OwnerInfoForm({ lookups }) {
  const { setValue, watch } = useFormContext();

  const watchOwnerGroups = watch("assoc_owner_group_ndx");

  const handleAddOwnerGroup = (values) => {
    setValue("assoc_owner_group_ndx", values, { shouldDirty: true });
  };

  const handleRemoveOwnerGroup = (value) => {
    const newValues = watchOwnerGroups.filter((item) => item !== value);
    setValue("assoc_owner_group_ndx", newValues, { shouldDirty: true });
  };

  return (
    <>
      <FormSection>
        <Typography variant="h5">Basic Info</Typography>

        <Grid container spacing={4}>
          <GridItem>
            <SimpleTextField name="first_name" label="First Name" required />
          </GridItem>
          <GridItem>
            <SimpleTextField name="last_name" label="Last Name" required />
          </GridItem>
          <GridItem>
            <SimpleSelect
              name="owner_type_ndx"
              label="Owner Type"
              displayField="owner_type_desc"
              valueField="owner_type_ndx"
              options={lookups?.ownerTypes ?? []}
            />
          </GridItem>
        </Grid>
        <Grid container spacing={4}>
          <GridItem>
            <SimpleTextField name="phone_no" label="Phone" />
          </GridItem>
        </Grid>
      </FormSection>

      <FormSection>
        <Typography variant="h5">Address Info</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={9}>
            <SimpleTextField name="address1" label="Address 1" />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={9}>
            <SimpleTextField name="address2" label="Address 2" />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <GridItem>
            <SimpleTextField name="city" label="City" />
          </GridItem>
          <GridItem>
            <SimpleTextField name="state" label="State" />
          </GridItem>
          <GridItem>
            <SimpleTextField name="zipcode" label="Zip" />
          </GridItem>
        </Grid>
      </FormSection>

      <Grid container spacing={4}>
        <Grid item md={6}>
          <AssocFormSection>
            <Typography variant="h5">Owner Groups</Typography>
            <RelationPicker
              onAdd={handleAddOwnerGroup}
              onRemove={handleRemoveOwnerGroup}
              options={lookups.ownerGroups}
              displayField="owner_group_desc"
              valueField="owner_group_ndx"
              value={watchOwnerGroups || []}
              urlPrefix="/data-management/owner-groups"
            />
          </AssocFormSection>
        </Grid>
      </Grid>
    </>
  );
}

export default OwnerInfoForm;

import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import useFetchData from "../../../hooks/useFetchData";

export const useOwnerInfo = (selectedRecord) => {
  const { getAccessTokenSilently } = useAuth0();

  const { data, isFetching, error, refetch } = useQuery(
    ["list-owners", selectedRecord],
    async () => {
      if (!selectedRecord) {
        return {};
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-owners/${selectedRecord}`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [ownerTypes] = useFetchData("list-owner-types", [], true);
  const [ownerGroups] = useFetchData("list-owner-groups", [], true);

  return {
    data,
    isLoading: isFetching,
    error,
    refetch,
    lookups: {
      ownerTypes,
      ownerGroups,
    },
  };
};

import { TextField as MuiTextField } from "@material-ui/core";
import { Controller } from "react-hook-form";

export const TextField = (props) => {
  const { control, name, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const { value = "", ...fieldRest } = field;
        return <MuiTextField {...rest} {...fieldRest} value={value} />;
      }}
    />
  );
};

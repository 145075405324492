import { useQuery } from "react-query";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import {
  Divider as MuiDivider,
  Link,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import React from "react";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import { SimpleSelect, SimpleTextField } from "../../BaseComponents";
import Button from "@material-ui/core/Button";
import { useApp } from "../../../../AppProvider";
import { useStateIfMounted } from "use-state-if-mounted";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Paper = styled(MuiPaper)(spacing);

const Instructions = styled.div`
  background-color: #edf6ff;
  border: 1px solid #c4e1ff;
  border-color: ${(props) => props.theme.palette.primary.main};
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 16px;
`;

export const schema = yup
  .object({
    imported_by: yup.string().required(),
    notes: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    lab_ndx: yup.number().required(),
  })
  .required();

export const DEFAULT_IMPORT_INFO_STATE = {
  imported_by: "Courtney Sandoval",
  notes: "",
  lab_ndx: 20,
};

const ImportNotesForm = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { doToast } = useApp();
  const [isFormSubmitting, setIsFormSubmitting] = useStateIfMounted(false);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_IMPORT_INFO_STATE,
  });

  const { isValid, isSubmitting } = methods.formState;

  const { data } = useQuery(
    ["list-labs"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-labs`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const onSubmit = async (formData) => {
    try {
      setIsFormSubmitting(true);

      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };

      await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/api/import-continuous-wq-data`,
        {
          ...formData,
          import_timestamp: new Date(),
        },
        { headers }
      );
      setIsFormSubmitting(false);
      doToast("success", "Data successfully imported!");
    } catch (err) {
      console.error(err);
      const message = err?.message ?? "Something went wrong";
      doToast("error", message);
    }
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} method="post">
      <FormProvider {...methods}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <SimpleSelect
              name="lab_ndx"
              label="Lab"
              options={data}
              displayField={"lab_name"}
              valueField={"lab_ndx"}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SimpleTextField
              autoFocus
              name="imported_by"
              label="Imported By"
              variant="outlined"
              required
              style={{
                width: 300,
              }}
              my={3}
            />
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "8px" }} spacing={4}>
          <Grid item xs={12} md={6}>
            <SimpleTextField
              name="notes"
              label="Notes"
              variant="outlined"
              multiline
              rows={4}
              style={{
                width: 300,
              }}
              my={3}
            />
          </Grid>
        </Grid>
      </FormProvider>
      <Button
        disabled={!isValid || isSubmitting || isFormSubmitting}
        startIcon={
          (isSubmitting || isFormSubmitting) && (
            <CircularProgress m={2} size={16} color="primary" />
          )
        }
        type={"submit"}
        variant={"contained"}
        color={"primary"}
        style={{ marginTop: "16px" }}
      >
        Submit{" "}
      </Button>
    </form>
  );
};

const ContinuousImporter = () => {
  return (
    <>
      <Helmet title="Continuous Water Quality Data Import" />
      <Typography variant="h3">Continuous Water Quality Data Import</Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Continuous Water Quality Data Import</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Instructions>
        <Typography variant={"h4"}>Instructions</Typography>

        <ol>
          <li>
            Copy and paste data into this spreadsheet, taking care to match the
            data up with the correct columns.
          </li>
          <li>
            Enter information about the data to be imported: lab name, who is
            performing the import, and any notes.
          </li>
          <li>
            <b>Click submit.</b> You will receive a message indicating whether
            your data import was successful or not.
          </li>
        </ol>

        <p>
          If you would like to delete data from a previous import, it is
          possible to do so on the{" "}
          <Link
            component={NavLink}
            exact
            to={"/data-management/delete-records/continuous"}
          >
            Delete Data Page
          </Link>
          .
        </p>
      </Instructions>

      <Paper p={6} mb={6}>
        <Typography variant="h4" gutterBottom display="inline">
          Enter Continuous Data Import Notes
        </Typography>
        <Typography variant="body1" display="inline" mb={6}>
          To import the data in the Google Sheet below, please enter details on
          the import and hit submit.
        </Typography>
        <ImportNotesForm />
      </Paper>
      <Paper p={6}>
        <Typography variant="h4" gutterBottom display="inline">
          Continuous Data Import Staging Workbook
        </Typography>
        <Typography variant="body1" gutterBottom display="inline">
          <strong>Note</strong>: If the spreadsheet does not show up below or is
          not working, please access it at{" "}
        </Typography>
        <a
          href="https://docs.google.com/spreadsheets/d/1NY0SRDH6pj-AJecQCAqPMEyf40NU_vcBpnQDteX-a54/edit?usp=sharing"
          target="_blank"
          rel="noreferrer"
        >
          https://docs.google.com/spreadsheets/d/1NY0SRDH6pj-AJecQCAqPMEyf40NU_vcBpnQDteX-a54/edit?usp=sharing
        </a>
        <iframe
          title={"Data Import Staging Workbook"}
          height="700"
          src="https://docs.google.com/spreadsheets/d/1NY0SRDH6pj-AJecQCAqPMEyf40NU_vcBpnQDteX-a54/edit?gid=0&amp;single=true&amp;widget=true&amp;headers=false&amp;chrome=false&amp;rm=minimal"
          width="100%"
        />
      </Paper>
    </>
  );
};

export default ContinuousImporter;

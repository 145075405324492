import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStateIfMounted } from "use-state-if-mounted";

export const useParameterGroups = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedRecord, setSelectedRecord] = useStateIfMounted(null);

  const { data, isFetching, error, refetch } = useQuery(
    ["list-parameter-groups"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-parameter-groups`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const columns = [
    {
      title: "Parameter Group Id",
      field: "parameter_group_ndx",
    },
    {
      title: "Name",
      field: "parameter_group_desc",
    },
    {
      title: "Notes",
      field: "notes",
    },
  ];

  return {
    selectedRecord,
    setSelectedRecord,
    data,
    isFetching,
    error,
    refetch,
    columns,
  };
};

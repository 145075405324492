import * as yup from "yup";

export const schema = yup
  .object({
    owner_id: yup.number().nullable(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    address1: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    address2: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    city: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    state: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    zipcode: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    phone_no: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    owner_type_ndx: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === "-1" ? null : value;
      })
      .nullable(),
    assoc_owner_group_ndx: yup.array().of(yup.number()).nullable(),
  })
  .required();

export const DEFAULT_OWNER_INFO_STATE = {
  owner_id: null,
  first_name: "",
  last_name: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipcode: "",
  phone_no: "",
  owner_type_ndx: "-1",
};

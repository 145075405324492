import React, { useCallback } from "react";
import styled from "styled-components/macro";
import {
  Accordion,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Typography as MuiTypography,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet-async";
import Button from "@material-ui/core/Button";
import Loader from "../../../components/Loader";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";
import { FILENAME } from "./constants";
import { FormProvider } from "react-hook-form";
import { Filter } from "./Filter";
import {
  AccordionBox,
  AccordionTitle,
  Chip,
  DownloadButton,
  DownloadButtonText,
  FilterSectionTitle,
  StyledAccordionDetails,
  TimePeriodSelection,
} from "./styledComponents";
import { useQueryAndDownload } from "./useQueryAndDownload";

const Grid = styled(MuiGrid)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const QueryAndDownload = () => {
  const {
    parameterGroups,
    parameters,
    structureGroups,
    structures,
    filterValues,
    isLoadingExport,
    isSubmitValid,
    isDownloadValid,
    handleSubmitFilters,
    handleExportClick,
    handleFilterValues,
    onFilterSelectAll,
    onFilterSelectNone,
    methods,
  } = useQueryAndDownload();

  const handleStartDateChange = useCallback(
    (date) => handleFilterValues("startDate", date),
    [handleFilterValues]
  );
  const handleEndDateChange = useCallback(
    (date) => handleFilterValues("endDate", date),
    [handleFilterValues]
  );
  const handleParameterChange = useCallback(
    (e) => {
      const value = +e.currentTarget.dataset.value;
      handleFilterValues("parameters", value);
    },
    [handleFilterValues]
  );
  const handleStructureChange = useCallback(
    (e) => {
      const value = +e.currentTarget.dataset.value;
      handleFilterValues("structures", value);
    },
    [handleFilterValues]
  );

  return (
    <>
      <Helmet title="Query & Download - Raw Data" />
      <Typography variant="h3" gutterBottom display="inline">
        Query & Download - Raw Data
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Query & Download - Raw Data</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="time-series"
          id="time-series"
        >
          <AccordionTitle>Filter Controls</AccordionTitle>
        </AccordionSummary>
        <AccordionBox>
          <StyledAccordionDetails>
            <FormProvider {...methods}>
              <form>
                <Typography variant="body1">
                  Use the filters to select the data you would like to download
                  and then click submit to start the download.
                </Typography>

                <FilterSectionTitle>Time Period</FilterSectionTitle>
                <TimePeriodSelection
                  startDate={filterValues.startDate}
                  endDate={filterValues.endDate}
                  handleStartDateChange={handleStartDateChange}
                  handleEndDateChange={handleEndDateChange}
                />

                <FilterSectionTitle>Parameters</FilterSectionTitle>
                <Filter
                  filterOptions={parameters}
                  filterName="parameters"
                  filterField="parameter_ndx"
                  filterValue={filterValues.parameters}
                  groupingLabel="Parameter Group"
                  groupingOptions={parameterGroups}
                  groupingName="parameterGroup"
                  searchName="parameterSearch"
                  searchLabel="Parameter Search"
                  onFilterChange={handleParameterChange}
                  onSelectAll={onFilterSelectAll}
                  onSelectNone={onFilterSelectNone}
                />

                <FilterSectionTitle>Locations</FilterSectionTitle>
                <Filter
                  filterOptions={structures}
                  filterName="structures"
                  filterField="structure_ndx"
                  filterValue={filterValues.structures}
                  groupingLabel="Structure Group"
                  groupingOptions={structureGroups}
                  groupingName="structureGroup"
                  searchName="structureSearch"
                  searchLabel="Location Search"
                  onFilterChange={handleStructureChange}
                  onSelectAll={onFilterSelectAll}
                  onSelectNone={onFilterSelectNone}
                />

                <Box mt={4}>
                  <Button
                    disabled={!isSubmitValid}
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    onClick={() => handleSubmitFilters()}
                  >
                    Submit Filters
                  </Button>
                </Box>
              </form>
            </FormProvider>
          </StyledAccordionDetails>
        </AccordionBox>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="time-series"
          id="time-series"
        >
          <AccordionTitle>Download Records </AccordionTitle>
        </AccordionSummary>
        <AccordionBox>
          <StyledAccordionDetails>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={9}>
                <Box display="flex" alignItems="center" justifyContent="left">
                  <Typography mr={2}>Recordcount for Selected Data:</Typography>
                  <Typography variant="h6" align="left">
                    <Chip
                      variant="outlined"
                      color="primary"
                      label={filterValues.recordCount || 0}
                    />
                  </Typography>
                  {filterValues?.recordCount > 110000 && (
                    <Typography variant="subtitle2" color="error" ml={2}>
                      Maximum records for download is <strong>110,000</strong>
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <DownloadButton
                  fullWidth
                  size="large"
                  onClick={() => handleExportClick(FILENAME)}
                  type="submit"
                  color="primary"
                  variant="outlined"
                  disabled={!isDownloadValid}
                  startIcon={
                    isLoadingExport ? <Loader color="inherit" /> : null
                  }
                >
                  <DownloadButtonText>
                    {isLoadingExport ? "Preparing..." : "Download"}
                  </DownloadButtonText>
                </DownloadButton>
              </Grid>
            </Grid>
          </StyledAccordionDetails>
        </AccordionBox>
      </Accordion>
    </>
  );
};

export default QueryAndDownload;

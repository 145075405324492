import { Grid, Typography } from "@material-ui/core";
import React from "react";

import { useFormContext } from "react-hook-form";

import {
  AssocFormSection,
  FormSection,
  GridItem,
  SimpleTextField,
  RelationPicker,
} from "../BaseComponents";

function ParameterGroupInfoForm({ lookups }) {
  const { setValue, watch } = useFormContext();

  const watchParameters = watch("assoc_parameter_ndx");

  const handleAddParameter = (values) => {
    setValue("assoc_parameter_ndx", values, { shouldDirty: true });
  };

  const handleRemoveParameter = (value) => {
    const newValues = watchParameters.filter((item) => item !== value);
    setValue("assoc_parameter_ndx", newValues, { shouldDirty: true });
  };

  return (
    <>
      <FormSection>
        <Typography variant="h5">Basic Info</Typography>

        <Grid container spacing={4}>
          <GridItem>
            <SimpleTextField
              name="parameter_group_desc"
              label="Name"
              required
            />
          </GridItem>
        </Grid>
        <Grid container spacing={4}>
          <GridItem xs={12} lg={6} xl={6}>
            <SimpleTextField
              multiline
              rows={4}
              name="notes"
              variant="outlined"
              label="Notes"
            />
          </GridItem>
        </Grid>
      </FormSection>

      <Grid container spacing={4}>
        <Grid item md={6}>
          <AssocFormSection>
            <Typography variant="h5">Parameters</Typography>
            <RelationPicker
              onAdd={handleAddParameter}
              onRemove={handleRemoveParameter}
              options={lookups.parameters}
              displayField="parameter_name"
              valueField="parameter_ndx"
              value={watchParameters || []}
              viewOnly
              urlPrefix={"/data-management/parameters"}
            />
          </AssocFormSection>
        </Grid>
      </Grid>
    </>
  );
}

export default ParameterGroupInfoForm;

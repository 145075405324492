import * as yup from "yup";

export const schema = yup
  .object({
    structure_ndx: yup.number().nullable(),
    owner_id: yup.number().required(),
    station_no: yup.number().required(),
    well_id: yup.string().required(),
    meas_source: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === "-1" ? null : value;
      })
      .required(),
    structure_type: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === "-1" ? null : value;
      })
      .required(),
    permit_number: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    well_number: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    township: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    rrange: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    section: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    q40: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    location: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    class_d: yup.string().nullable(),
    contract: yup.string().nullable(),
    analysis: yup.string().nullable(),
    depth: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    depthid: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    datumdiff: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    bedrock: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    altitude: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    easting: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    northing: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    x_lon_nad83: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    y_lat_nad83: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    assoc_structure_group_ndx: yup.array().of(yup.number()).nullable(),
  })
  .required();

export const DEFAULT_STRUCTURE_INFO_STATE = {
  structure_ndx: null,
  owner_id: "-1",
  station_no: "",
  well_id: "",
  meas_source: "-1",
  structure_type: "-1",
  permit_number: "",
  well_number: "",
  township: "",
  rrange: "",
  section: "",
  q40: "",
  location: "",
  class_d: "",
  contract: "",
  analysis: "",
  depth: "",
  depthid: "",
  datumdiff: "",
  bedrock: "",
  altitude: "",
  easting: "",
  northing: "",
  x_lon_nad83: "",
  y_lat_nad83: "",
};

import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

// Fetch a list of sources to add to the map
const useSources = (routeType) => {
  const { getAccessTokenSilently } = useAuth0();
  const [sources, setSources] = useState([]);
  const { data, isError, isLoading } = useQuery(
    ["Sources", `/api/water-quality-map/sources/${routeType}`],
    async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${accessToken}` };
        return await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/water-quality-map/sources/${routeType}`,
          { headers }
        );
      } catch (err) {
        console.error(err);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setSources(data?.data || []);
  }, [data]);

  return { isError, isLoading, sources, setSources };
};

export default useSources;

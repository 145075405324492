import {
  Accordion,
  AccordionActions,
  AccordionDetails as MuiAccordingDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Button,
  Divider,
  Paper,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import styled from "styled-components/macro";

import Loader from "../../../components/Loader";
import { schema, DEFAULT_METHOD_INFO_STATE } from "./constants";
import { useSubmitData } from "../../../hooks/useSubmitData";
import { useMethodInfo } from "./useMethodInfo";

import { CircularProgress } from "@material-ui/core";
import MethodInfoForm from "./MethodInfoForm";
import { useForm, FormProvider } from "react-hook-form";

const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 0 16px;
`;

const AccordionDetails = styled(MuiAccordingDetails)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px;
`;

export const getButtonIcon = (isFormSubmitting) => {
  if (isFormSubmitting) {
    return <CircularProgress color="inherit" size={20} />;
  }
};

export const getButtonText = (isFormSubmitting, isNewWell = false) => {
  if (isFormSubmitting) {
    if (isNewWell) {
      return "Creating";
    } else {
      return "Saving";
    }
  }
  if (isNewWell) {
    return "Create";
  } else {
    return "Save";
  }
};

function MethodsInfo({
  selectedRecord,
  setSelectedRecord,
  refetchMethods = () => {},
  isEditMode = false,
}) {
  const { data, isLoading, error, refetch } = useMethodInfo(selectedRecord);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_METHOD_INFO_STATE,
    values: {
      method_ndx: data?.method_ndx || DEFAULT_METHOD_INFO_STATE.method_ndx,
      method_desc_official:
        data?.method_desc_official ||
        DEFAULT_METHOD_INFO_STATE.method_desc_official,
      notes: data?.notes || DEFAULT_METHOD_INFO_STATE.notes,
    },
  });

  const isDirty = methods.formState.isDirty;
  const isValid = methods.formState.isValid;

  const handleRefreshAfterSubmit = () => {
    refetchMethods();
  };

  const { handleSubmit: onSubmit, isFormSubmitting } = useSubmitData(
    selectedRecord,
    setSelectedRecord,
    data,
    "list-methods",
    "method_ndx",
    refetch,
    handleRefreshAfterSubmit
  );

  const handleReset = () => {
    methods.reset(data);
  };

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !data?.method_ndx && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !data?.method_ndx) ? "none" : "block",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box width="100%">
            <Typography variant="h3">Method Information</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <FormProvider {...methods}>
            <form
              id="method-info-form"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <MethodInfoForm />
            </form>
          </FormProvider>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button size="small" onClick={handleReset}>
            Reset
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            type="submit"
            form="method-info-form"
            disabled={!isDirty || (isDirty && !isValid) || isFormSubmitting}
            startIcon={getButtonIcon(isFormSubmitting)}
            style={{ width: "100px" }}
          >
            {getButtonText(isFormSubmitting, selectedRecord === 0)}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  );
}

export default MethodsInfo;

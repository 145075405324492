import {
  Accordion,
  AccordionActions,
  AccordionDetails as MuiAccordingDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Button,
  Divider,
  Paper,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import styled from "styled-components/macro";

import Loader from "../../../components/Loader";
import { schema, DEFAULT_OWNER_INFO_STATE } from "./constants";
import { useSubmitData } from "../../../hooks/useSubmitData";
import { useOwnerInfo } from "./useOwnerInfo";

import { CircularProgress } from "@material-ui/core";
import OwnerInfoForm from "./OwnerInfoForm";
import { useForm, FormProvider } from "react-hook-form";

const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 0 16px;
`;

const AccordionDetails = styled(MuiAccordingDetails)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px;
`;

export const getButtonIcon = (isFormSubmitting) => {
  if (isFormSubmitting) {
    return <CircularProgress color="inherit" size={20} />;
  }
};

export const getButtonText = (isFormSubmitting, isNewWell = false) => {
  if (isFormSubmitting) {
    if (isNewWell) {
      return "Creating";
    } else {
      return "Saving";
    }
  }
  if (isNewWell) {
    return "Create";
  } else {
    return "Save";
  }
};

function OwnerInfo({
  selectedRecord,
  setSelectedRecord,
  refetchOwners = () => {},
  isEditMode = false,
}) {
  const { data, isLoading, error, refetch, lookups } =
    useOwnerInfo(selectedRecord);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_OWNER_INFO_STATE,
    values: {
      owner_id: data?.owner_id || DEFAULT_OWNER_INFO_STATE.owner_id,
      first_name: data?.first_name || DEFAULT_OWNER_INFO_STATE.first_name,
      last_name: data?.last_name || DEFAULT_OWNER_INFO_STATE.last_name,
      address1: data?.address1 || DEFAULT_OWNER_INFO_STATE.address1,
      address2: data?.address2 || DEFAULT_OWNER_INFO_STATE.address2,
      city: data?.city || DEFAULT_OWNER_INFO_STATE.city,
      state: data?.state || DEFAULT_OWNER_INFO_STATE.state,
      zipcode: data?.zipcode || DEFAULT_OWNER_INFO_STATE.zipcode,
      phone_no: data?.phone_no || DEFAULT_OWNER_INFO_STATE.phone_no,
      owner_type_ndx:
        data?.owner_type_ndx || DEFAULT_OWNER_INFO_STATE.owner_type_ndx,
    },
  });

  const isDirty = methods.formState.isDirty;
  const isValid = methods.formState.isValid;

  const handleRefreshAfterSubmit = () => {
    refetchOwners();
  };

  const { handleSubmit: onSubmit, isFormSubmitting } = useSubmitData(
    selectedRecord,
    setSelectedRecord,
    data,
    "list-owners",
    "owner_id",
    refetch,
    handleRefreshAfterSubmit
  );

  const handleReset = () => {
    methods.reset(data);
  };

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !data?.owner_id && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !data?.owner_id) ? "none" : "block",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box width="100%">
            <Typography variant="h3">Owner Information</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <FormProvider {...methods}>
            <form
              id="owner-info-form"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <OwnerInfoForm lookups={lookups} />
            </form>
          </FormProvider>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button size="small" onClick={handleReset}>
            Reset
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            type="submit"
            form="owner-info-form"
            disabled={!isDirty || (isDirty && !isValid) || isFormSubmitting}
            startIcon={getButtonIcon(isFormSubmitting)}
            style={{ width: "100px" }}
          >
            {getButtonText(isFormSubmitting, selectedRecord === 0)}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  );
}

export default OwnerInfo;

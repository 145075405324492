import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useFormContext } from "react-hook-form";

import {
  AssocFormSection,
  FormSection,
  GridItem,
  SimpleTextField,
  SimpleSelect,
  RelationPicker,
} from "../BaseComponents";

/**
 * All of the misc location-related fields for a structure
 * are basic text fields, so we can just map over them
 * instead of writing them out individually
 * The first value in the nested array is the name of the field
 * The second value is the label for the field
 */
const STRUCTURE_LOCATION_INPUTS = [
  ["township", "Township"],
  ["rrange", "Range"],
  ["section", "Section"],
  ["q40", "Q40"],
  ["class_d", "Class D"],
  ["analysis", "Analysis"],
  ["depth", "Depth"],
  ["depthid", "Depth ID"],
  ["datumdiff", "Datum Diff"],
  ["bedrock", "Bedrock"],
  ["altitude", "Altitude"],
  ["northing", "Northing"],
  ["easting", "Easting"],
  ["x_lon_nad83", "Longitude"],
  ["y_lat_nad83", "Latitude"],
];

function StructureInfoForm({ lookups }) {
  const { setValue, watch } = useFormContext();

  const watchStructureGroups = watch("assoc_structure_group_ndx");

  const handleAddStructureGroups = (values) => {
    setValue("assoc_structure_group_ndx", values, { shouldDirty: true });
  };

  const handleRemoveStructureGroups = (value) => {
    const newValues = watchStructureGroups.filter((item) => item !== value);
    setValue("assoc_structure_group_ndx", newValues, { shouldDirty: true });
  };

  return (
    <>
      <FormSection>
        <Typography variant="h5">Basic Info</Typography>

        <Grid container spacing={4}>
          <GridItem>
            <SimpleSelect
              name="owner_id"
              label="Owner"
              displayField="owner_name"
              valueField="owner_id"
              options={lookups?.owners ?? []}
            />
          </GridItem>

          <GridItem>
            <SimpleTextField name="station_no" label="Station No" />
          </GridItem>
          <GridItem>
            <SimpleTextField name="well_id" label="Well ID" />
          </GridItem>
          <GridItem xs={12} lg={6} xl={6}>
            <SimpleTextField
              multiline
              rows={4}
              name="location"
              variant="outlined"
              label="Location Description"
            />
          </GridItem>
        </Grid>
      </FormSection>

      <FormSection>
        <Typography variant="h5">Measurement Details</Typography>
        <Grid container spacing={4}>
          <GridItem>
            <SimpleSelect
              name="meas_source"
              label="Measurement Source"
              displayField="description"
              valueField="meas_source"
              options={lookups?.measurementSources ?? []}
            />
          </GridItem>
          <GridItem>
            <SimpleSelect
              name="structure_type"
              label="Structure Type"
              displayField="description"
              valueField="structure_type"
              options={lookups?.structureTypes ?? []}
            />
          </GridItem>
        </Grid>
      </FormSection>

      <FormSection>
        <Typography variant="h5">Administrative Info</Typography>
        <Grid container spacing={4}>
          <GridItem>
            <SimpleTextField name="permit_number" label="Permit No" />
          </GridItem>

          <GridItem>
            <SimpleTextField name="well_number" label="Well No" />
          </GridItem>

          <GridItem>
            <SimpleTextField name="contract" label="Contract" />
          </GridItem>
        </Grid>
      </FormSection>

      <FormSection>
        <Typography variant="h5">Location Info</Typography>
        <Grid container spacing={4}>
          {STRUCTURE_LOCATION_INPUTS.map(([name, label]) => (
            <GridItem key={name}>
              <SimpleTextField name={name} label={label} />
            </GridItem>
          ))}
        </Grid>
      </FormSection>

      <Grid container spacing={4}>
        <Grid item md={6}>
          <AssocFormSection>
            <Typography variant="h5">Structure Groups</Typography>
            <RelationPicker
              onAdd={handleAddStructureGroups}
              onRemove={handleRemoveStructureGroups}
              options={lookups.structureGroups}
              displayField="structure_group_desc"
              valueField="structure_group"
              value={watchStructureGroups || []}
              urlPrefix={"/data-management/structure-groups"}
            />
          </AssocFormSection>
        </Grid>
      </Grid>
    </>
  );
}

export default StructureInfoForm;

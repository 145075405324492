import { Grid, Typography } from "@material-ui/core";
import React from "react";

import { FormSection, GridItem, SimpleTextField } from "../BaseComponents";

function UnitInfoForm() {
  return (
    <>
      <FormSection>
        <Typography variant="h5">Basic Info</Typography>

        <Grid container spacing={4}>
          <GridItem>
            <SimpleTextField
              name="unit_desc_official"
              label="Unit Name"
              required
            />
          </GridItem>
        </Grid>
      </FormSection>
    </>
  );
}

export default UnitInfoForm;

export const BASEMAP_STYLES = [
  {
    style: "outdoors-v11",
    name: "Outdoors",
    url: "mapbox://styles/mapbox/outdoors-v11",
  },
  {
    style: "streets-v11",
    name: "Streets",
    url: "mapbox://styles/mapbox/streets-v11",
  },
  {
    style: "satellite-streets-v11",
    name: "Satellite",
    url: "mapbox://styles/mapbox/satellite-streets-v11",
  },
  {
    style: "light-v10",
    name: "Light",
    url: "mapbox://styles/mapbox/light-v10",
  },
  {
    style: "dark-v10",
    name: "Dark",
    url: "mapbox://styles/mapbox/dark-v10",
  },
];

export const DEFAULT_MAP_CENTER = [-104.65976, 40.13183];

export const INIT_MAP_CONFIG = {
  style: BASEMAP_STYLES[0].url,
  center: DEFAULT_MAP_CENTER,
  zoom: 8.2,
  preserveDrawingBuffer: true,
};

export const MONITORING_LOCATIONS_LAYER_ID = "locations-circle";
export const MONITORING_LOCATIONS_LABELS_LAYER_ID = "locations-symbol";
export const INIT_FILTER_VALUES = {
  ownerGroups: {
    layerId: MONITORING_LOCATIONS_LAYER_ID,
    layerFieldName: "owner_group_desc",
    options: [],
    type: "multi-select-array",
    value: [],
  },
  structureGroups: {
    layerId: MONITORING_LOCATIONS_LAYER_ID,
    layerFieldName: "structure_group_desc",
    options: [],
    type: "multi-select-array",
    value: [],
  },
  graphMode: {
    layerId: MONITORING_LOCATIONS_LAYER_ID,
    layerFieldName: "ndx",
    type: "graphMode",
    value: [""],
  },
  moreThanA: {
    layerId: MONITORING_LOCATIONS_LAYER_ID,
    layerFieldName: "more_than_a",
    type: "boolean",
    value: false,
  },
  moreThanB: {
    layerId: MONITORING_LOCATIONS_LAYER_ID,
    layerFieldName: "more_than_b",
    type: "boolean",
    value: false,
  },
  moreThanC: {
    layerId: MONITORING_LOCATIONS_LAYER_ID,
    layerFieldName: "more_than_c",
    type: "boolean",
    value: false,
  },
  dataRecentA: {
    layerId: MONITORING_LOCATIONS_LAYER_ID,
    layerFieldName: "data_recent_a",
    type: "boolean",
    value: false,
  },
  dataRecentB: {
    layerId: MONITORING_LOCATIONS_LAYER_ID,
    layerFieldName: "data_recent_b",
    type: "boolean",
    value: false,
  },
};

const layerId = "locations-circle";
export const INIT_STYLE_VALUES = () => {
  return {
    ownerGroups: {
      id: "ownerGroups",
      layerId,
      layerFieldName: "owner_group_desc",
      name: "Owner Groups",
      options: [],
      type: "multi-select-array",
      value: [],
      paint: {
        "circle-color": "#1e8dd2",
        "circle-stroke-width": 2,
        "circle-stroke-color": "#fff",
      },
    },
    structureGroups: {
      id: "structureGroups",
      layerId,
      layerFieldName: "structure_group_desc",
      name: "Structure Groups",
      options: [],
      type: "multi-select-array",
      value: [],
      paint: {
        "circle-color": [
          "match",
          ["get", "structure_group_desc"],
          "No Values Available",
          "#fff",
          "#fff",
        ],
      },
    },
    default: {
      id: "default",
      layerId,
      layerFieldName: "",
      name: "Default",
      paint: {
        "circle-color": "#1e8dd2",
        "circle-stroke-width": 2,
        "circle-stroke-color": "#fff",
      },
    },
  };
};

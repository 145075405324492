import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import MuiDivider from "@material-ui/core/Divider";
import MuiTypography from "@material-ui/core/Typography";
import React from "react";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const ConfirmDeleteDialog = ({ open, onConfirm, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Records</DialogTitle>
      <Divider style={{ marginBottom: "16px" }} />
      <DialogContent style={{ height: 100 }}>
        <DialogContentText>
          <Typography variant="body1" color={"textPrimary"}>
            Are you sure you want to delete these records?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="outlined"
          onClick={onConfirm}
          className="error"
          autoFocus
        >
          Yes, delete records
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;

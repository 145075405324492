import {
  Accordion,
  AccordionActions,
  AccordionDetails as MuiAccordingDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Button,
  Divider,
  Paper,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import styled from "styled-components/macro";

import Loader from "../../../components/Loader";
import { schema, DEFAULT_STRUCTURE_INFO_STATE } from "./constants";
import { useSubmitData } from "../../../hooks/useSubmitData";
import { useStructureInfo } from "./useStructureInfo";

import { CircularProgress } from "@material-ui/core";
import StructureInfoForm from "./StructureInfoForm";
import { useForm, FormProvider } from "react-hook-form";

const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 0 16px;
`;

const AccordionDetails = styled(MuiAccordingDetails)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px;
`;

export const getButtonIcon = (isFormSubmitting) => {
  if (isFormSubmitting) {
    return <CircularProgress color="inherit" size={20} />;
  }
};

export const getButtonText = (isFormSubmitting, isNewWell = false) => {
  if (isFormSubmitting) {
    if (isNewWell) {
      return "Creating";
    } else {
      return "Saving";
    }
  }
  if (isNewWell) {
    return "Create";
  } else {
    return "Save";
  }
};

function StructureInfo({
  selectedRecord,
  setSelectedRecord,
  refetchStructures = () => {},
  isEditMode = false,
}) {
  const { data, isLoading, error, refetch, lookups } =
    useStructureInfo(selectedRecord);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_STRUCTURE_INFO_STATE,
    values: {
      structure_ndx:
        data?.structure_ndx || DEFAULT_STRUCTURE_INFO_STATE.structure_ndx,
      owner_id: data?.owner_id || DEFAULT_STRUCTURE_INFO_STATE.owner_id,
      station_no: data?.station_no || DEFAULT_STRUCTURE_INFO_STATE.station_no,
      well_id: data?.well_id || DEFAULT_STRUCTURE_INFO_STATE.well_id,
      meas_source:
        data?.meas_source || DEFAULT_STRUCTURE_INFO_STATE.meas_source,
      structure_type:
        data?.structure_type || DEFAULT_STRUCTURE_INFO_STATE.structure_type,
      permit_number:
        data?.permit_number || DEFAULT_STRUCTURE_INFO_STATE.permit_number,
      well_number:
        data?.well_number || DEFAULT_STRUCTURE_INFO_STATE.well_number,
      township: data?.township || DEFAULT_STRUCTURE_INFO_STATE.township,
      rrange: data?.rrange || DEFAULT_STRUCTURE_INFO_STATE.rrange,
      section: data?.section || DEFAULT_STRUCTURE_INFO_STATE.section,
      q40: data?.q40 || DEFAULT_STRUCTURE_INFO_STATE.q40,
      location: data?.location || DEFAULT_STRUCTURE_INFO_STATE.location,
      class_d: data?.class_d || DEFAULT_STRUCTURE_INFO_STATE.class_d,
      contract: data?.contract || DEFAULT_STRUCTURE_INFO_STATE.contract,
      analysis: data?.analysis || DEFAULT_STRUCTURE_INFO_STATE.analysis,
      depth: data?.depth || DEFAULT_STRUCTURE_INFO_STATE.depth,
      depthid: data?.depthid || DEFAULT_STRUCTURE_INFO_STATE.depthid,
      datumdiff: data?.datumdiff || DEFAULT_STRUCTURE_INFO_STATE.datumdiff,
      bedrock: data?.bedrock || DEFAULT_STRUCTURE_INFO_STATE.bedrock,
      altitude: data?.altitude || DEFAULT_STRUCTURE_INFO_STATE.altitude,
      easting: data?.easting || DEFAULT_STRUCTURE_INFO_STATE.easting,
      northing: data?.northing || DEFAULT_STRUCTURE_INFO_STATE.northing,
      x_lon_nad83:
        data?.x_lon_nad83 || DEFAULT_STRUCTURE_INFO_STATE.x_lon_nad83,
      y_lat_nad83:
        data?.y_lat_nad83 || DEFAULT_STRUCTURE_INFO_STATE.y_lat_nad83,
    },
  });

  const isDirty = methods.formState.isDirty;
  const isValid = methods.formState.isValid;

  const handleRefreshAfterSubmit = () => {
    refetchStructures();
  };

  const { handleSubmit: onSubmit, isFormSubmitting } = useSubmitData(
    selectedRecord,
    setSelectedRecord,
    data,
    "list-structures",
    "structure_ndx",
    refetch,
    handleRefreshAfterSubmit
  );

  const handleReset = () => {
    methods.reset(data);
  };

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !data?.structure_ndx && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !data?.structure_ndx)
              ? "none"
              : "block",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box width="100%">
            <Typography variant="h3">Structure Information</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <FormProvider {...methods}>
            <form
              id="structure-info-form"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <StructureInfoForm lookups={lookups} />
            </form>
          </FormProvider>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button size="small" onClick={handleReset}>
            Reset
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            type="submit"
            form="structure-info-form"
            disabled={!isDirty || (isDirty && !isValid) || isFormSubmitting}
            startIcon={getButtonIcon(isFormSubmitting)}
            style={{ width: "100px" }}
          >
            {getButtonText(isFormSubmitting, selectedRecord === 0)}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  );
}

export default StructureInfo;

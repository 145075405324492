import { InputAdornment, TextField as MuiTextField } from "@material-ui/core";
import MuiSearchIcon from "@material-ui/icons/Search";
import { Controller } from "react-hook-form";
import styled from "styled-components/macro";

const SearchIcon = styled(MuiSearchIcon)`
  color: #252526;
  width: 16px;
`;

export const Search = (props) => {
  const { control, name, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const { value = "", ...fieldRest } = field;

        return (
          <MuiTextField
            {...rest}
            {...fieldRest}
            InputProps={{
              placeholder: "Search",
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={value}
          />
        );
      }}
    />
  );
};

import { Grid, Typography } from "@material-ui/core";
import React from "react";

import { useFormContext } from "react-hook-form";

import {
  AssocFormSection,
  FormSection,
  GridItem,
  SimpleTextField,
  RelationPicker,
} from "../BaseComponents";

function OwnerGroupInfoForm({ lookups }) {
  const { setValue, watch } = useFormContext();

  const watchOwners = watch("assoc_owner_ndx");

  const handleAddOwner = (values) => {
    setValue("assoc_owner_ndx", values, { shouldDirty: true });
  };

  const handleRemoveOwner = (value) => {
    const newValues = watchOwners.filter((item) => item !== value);
    setValue("assoc_owner_ndx", newValues, { shouldDirty: true });
  };

  return (
    <>
      <FormSection>
        <Typography variant="h5">Basic Info</Typography>

        <Grid container spacing={4}>
          <GridItem>
            <SimpleTextField name="owner_group_desc" label="Name" required />
          </GridItem>

          <GridItem>
            <SimpleTextField name="display_order" label="Display Order" />
          </GridItem>
        </Grid>
        <Grid container spacing={4}>
          <GridItem xs={12} lg={6} xl={6}>
            <SimpleTextField
              multiline
              rows={4}
              name="notes"
              variant="outlined"
              label="Notes"
            />
          </GridItem>
        </Grid>
      </FormSection>

      <Grid container spacing={4}>
        <Grid item md={6}>
          <AssocFormSection>
            <Typography variant="h5">Owners</Typography>
            <RelationPicker
              onAdd={handleAddOwner}
              onRemove={handleRemoveOwner}
              options={lookups.owners}
              displayField="owner_name"
              valueField="owner_id"
              value={watchOwners || []}
              viewOnly
              urlPrefix={"/data-management/owners"}
            />
          </AssocFormSection>
        </Grid>
      </Grid>
    </>
  );
}

export default OwnerGroupInfoForm;

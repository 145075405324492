import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { convertUTCDateToLocalDate } from "../../../utils";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Chip as MuiChip,
  Divider as MuiDivider,
  FormControl,
  Grid as MuiGrid,
  isWidthUp,
  Typography as MuiTypography,
  withWidth,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet-async";
import Button from "@material-ui/core/Button";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useApp } from "../../../AppProvider";
import Loader from "../../../components/Loader";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { DEFAULT_FILTER_VALUES, SCHEMA } from "./constants";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Filter } from "./Filter";
import useDebounce from "../../../hooks/useDebounce";

const Grid = styled(MuiGrid)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};
  margin-top: 1px;
  margin-bottom: 1px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 790;
  background-color: ${(props) => props.rgbcolor};
`;

const Typography = styled(MuiTypography)(spacing);

const useFetch = (endpoint, query) => {
  const { getAccessTokenSilently } = useAuth0();

  const { data, isLoading, refetch } = useQuery(
    [endpoint, query],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}`,
          { headers, params: query }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  return {
    data,
    isLoading,
    refetch,
  };
};

const QueryAndDownload = ({ width }) => {
  const { doToast, currentUser } = useApp();
  const { getAccessTokenSilently } = useAuth0();
  const [filterValues, setFilterValues] = useState(DEFAULT_FILTER_VALUES);

  const methods = useForm({
    resolver: yupResolver(SCHEMA),
    defaultValues: DEFAULT_FILTER_VALUES,
  });

  const watch = methods.watch;
  const setValue = methods.setValue;

  const watchParameterSearch = watch("parameterSearch");
  const watchStructureSearch = watch("structureSearch");

  const debouncedParameterSearch = useDebounce(watchParameterSearch, 500);
  const debouncedStructureSearch = useDebounce(watchStructureSearch, 500);

  const { data: parameters, isLoading: isParametersLoading } = useFetch(
    "list-parameters",
    {
      parameterGroup: DEFAULT_FILTER_VALUES.parameterGroup,
      parameterSearch: debouncedParameterSearch,
    }
  );

  const { data: structureGroups } = useFetch("list-structure-groups");

  const { data: structures, isLoading: isStructuresLoading } = useFetch(
    "list-structures",
    {
      structureGroup: DEFAULT_FILTER_VALUES.structureGroup,
      structureSearch: debouncedStructureSearch,
    }
  );

  const { data: userSelections } = useFetch(
    `data-download-continuous-user-filters/${currentUser?.sub}`
  );

  const onFilterSelectAll = (filterType, filterOptions, filterField) => {
    setFilterValues((prevState) => {
      const selectedValues = filterOptions.map((x) => x[filterField]);
      const combinedValues = new Set([
        ...prevState[filterType],
        ...selectedValues,
      ]);
      return { ...prevState, [filterType]: [...combinedValues] };
    });
  };

  const onFilterSelectNone = (filterType, filterOptions, filterField) => {
    setFilterValues((prevState) => {
      const filteredValues = prevState[filterType].filter((prevValue) =>
        filterOptions.every((option) => option[filterField] !== prevValue)
      );
      return { ...prevState, [filterType]: filteredValues };
    });
  };

  const getFilterNameByIndex = (
    name,
    filterOptions,
    filterField,
    filterIndex
  ) => {
    let filter = filterOptions?.find((x) => x[filterIndex] === name);
    return filter?.[filterField];
  };

  const handleFilterValues = (name, value) => {
    const isParametersFilter = name === "parameters";
    const isStructuresFilter = name === "structures";

    if (isParametersFilter || isStructuresFilter) {
      const selectedValue = +value.currentTarget.dataset.value;
      setFilterValues((prevState) => {
        const existingVals = [...prevState[name]];
        const existingIndex = existingVals.indexOf(selectedValue);
        existingIndex > -1
          ? existingVals.splice(existingIndex, 1)
          : existingVals.push(selectedValue);

        return {
          ...prevState,
          [name]: existingVals,
        };
      });
    } else {
      setFilterValues((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
  };

  const { data: recordCount } = useQuery(
    ["report-user-filters-continuous-recordcounts", currentUser],
    async () => {
      if (currentUser?.sub) {
        try {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/report-user-filters-continuous-recordcounts`,
            {
              uuid: currentUser?.sub,
            },
            { headers }
          );
          return data[0]?.sel_recordcount ?? 0;
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (recordCount) {
      handleFilterValues("recordCount", recordCount);
    }
  }, [recordCount]);

  useEffect(() => {
    if (!!userSelections) {
      setFilterValues((prevState) => {
        return {
          ...prevState,
          parameters: userSelections.parameters,
          structures: userSelections.locations,
          startDate: convertUTCDateToLocalDate(
            new Date(userSelections.start_date)
          ),
          endDate: convertUTCDateToLocalDate(new Date(userSelections.end_date)),
        };
      });
    }
  }, [userSelections, setValue]);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const handleSubmitFilters = () => {
    const user = currentUser?.sub;
    if (!user) {
      doToast("error", "User not provided. Please log in.");
      return;
    }

    setIsSubmitLoading(true);
    return (async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${accessToken}` };

        await axios.put(
          `${process.env.REACT_APP_ENDPOINT}/api/data-download-continuous-user-filters`,
          {
            user_uuid: user,
            parameters: filterValues.parameters,
            locations: filterValues.structures,
            start_date: filterValues.startDate,
            end_date: filterValues.endDate,
            parameter_group_ndx: DEFAULT_FILTER_VALUES.parameterGroup,
            location_group_ndx: DEFAULT_FILTER_VALUES.structureGroup,
          },
          {
            headers,
          }
        );

        const { data } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/report-user-filters-continuous-recordcounts`,
          {
            uuid: user,
          },
          { headers }
        );
        handleFilterValues("recordCount", data[0]?.sel_recordcount ?? 0);

        doToast("success", "New filters were applied to the database");
        setIsSubmitLoading(false);
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
        setIsSubmitLoading(false);
      }
    })();
  };

  const [isExportLoading, setIsExportLoading] = useState(false);
  const handleExportClick = () => {
    const user = currentUser?.sub;
    if (!user) {
      doToast("error", "User not provided. Please log in.");
      return;
    }

    setIsExportLoading(true);
    async function send() {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        let { data: timeseriesData } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/report-user-selected-data-continuous`,
          {
            uuid: user,
          },
          { headers }
        );

        const timeseriesDataCsvString = [
          [
            `"Results for constituents: ${filterValues.parameters
              .map((parameter) =>
                getFilterNameByIndex(
                  parameter,
                  parameters,
                  "parameter_name",
                  "parameter_ndx"
                )
              )
              .join(", ")}"`,
          ],
          [
            `"Results for locations: ${filterValues.structures
              .map((structure) =>
                getFilterNameByIndex(
                  structure,
                  structures,
                  "structure_name",
                  "structure_ndx"
                )
              )
              .join(", ")}"`,
          ],
          [`"Time Series Data"`],
          [
            "Location Name",
            "Sample Date",
            "Sample Time",
            "Parameter",
            "Value",
            "Units",
            "Lat",
            "Lon",
          ],
          ...timeseriesData.map((item) => [
            `"${item.location_name ?? ""}"`,
            `"${item.sample_date ?? ""}"`,
            `"${item.sample_time ?? ""}"`,
            `"${item.parameter_name ?? ""}"`,
            `"${item.result_value ?? ""}"`,
            `"${item.units ?? ""}"`,
            `"${item.lat ?? ""}"`,
            `"${item.lon ?? ""}"`,
          ]),
        ]
          .map((e) => e.join(","))
          .join("\n");

        const a = document.createElement("a");
        a.href =
          "data:attachment/csv," + encodeURIComponent(timeseriesDataCsvString);
        a.target = "_blank";
        a.download = `Continuous Time Series Data.csv`;
        document.body.appendChild(a);
        a.click();
        // return csvString;

        setIsExportLoading(false);
      } catch (err) {
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
          setIsExportLoading(false);
        } else {
          console.error(err);
          setIsExportLoading(false);
        }
      }
    }
    send();
  };

  return (
    <>
      <Helmet title="Query & Download - Continuous Data" />
      <Typography variant="h3" gutterBottom display="inline">
        Query & Download - Continuous Data
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Query & Download - Continuous Data</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {!isParametersLoading && !isStructuresLoading && (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="time-series"
            id="time-series"
          >
            <Typography variant="h4" ml={3}>
              Filter Controls
            </Typography>
          </AccordionSummary>
          <Box ml={3} mr={3} mb={isWidthUp("xl", width) ? 0 : 3}>
            <AccordionDetails style={{ padding: 0 }}>
              <FormProvider {...methods}>
                <form>
                  <Typography variant={"body1"} mb={4}>
                    Use the filters to select the data you would like to
                    download and then click submit to start the download.
                  </Typography>
                  <Typography variant="h5" mb={6}>
                    Time Period
                  </Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl
                      style={{
                        width: 300,
                        marginRight: "12px",
                        marginBottom: "12px",
                      }}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        autoOk
                        inputVariant="outlined"
                        disableToolbar
                        format="MM/dd/yyyy"
                        id="start-date-picker"
                        label="Start Date"
                        value={filterValues.startDate}
                        onChange={(date) =>
                          handleFilterValues("startDate", date)
                        }
                        InputAdornmentProps={{
                          "aria-label": "change start date",
                        }}
                      />
                    </FormControl>
                    <FormControl
                      style={{
                        width: 300,
                      }}
                    >
                      <KeyboardDatePicker
                        autoOk
                        inputVariant="outlined"
                        disableToolbar
                        format="MM/dd/yyyy"
                        id="end-date-picker"
                        label="End Date"
                        value={filterValues.endDate}
                        onChange={(date) => handleFilterValues("endDate", date)}
                        InputAdornmentProps={{
                          "aria-label": "change end date",
                        }}
                      />
                    </FormControl>
                  </MuiPickersUtilsProvider>
                  <Typography variant="h5" mt={8} mb={8}>
                    Parameters
                  </Typography>
                  <Filter
                    filterOptions={parameters}
                    filterName={"parameters"}
                    filterValue={filterValues.parameters}
                    searchName={"parameterSearch"}
                    searchLabel={"Parameter Search"}
                    onFilterChange={(e) => {
                      handleFilterValues("parameters", e);
                    }}
                    onSelectAll={() =>
                      onFilterSelectAll(
                        "parameters",
                        parameters,
                        "parameter_ndx"
                      )
                    }
                    onSelectNone={() =>
                      onFilterSelectNone(
                        "parameters",
                        parameters,
                        "parameter_ndx"
                      )
                    }
                  />
                  <Typography variant="h5" mt={8} mb={8}>
                    Locations
                  </Typography>
                  <Filter
                    filterOptions={structures}
                    filterName={"structures"}
                    filterValue={filterValues.structures}
                    groupingLabel={"Structure Group"}
                    groupingOptions={structureGroups}
                    groupingName={"structureGroup"}
                    searchName={"structureSearch"}
                    searchLabel={"Location Search"}
                    onFilterChange={(e) => {
                      handleFilterValues("structures", e);
                    }}
                    onSelectAll={() =>
                      onFilterSelectAll(
                        "structures",
                        structures,
                        "structure_ndx"
                      )
                    }
                    onSelectNone={() =>
                      onFilterSelectNone(
                        "structures",
                        structures,
                        "structure_ndx"
                      )
                    }
                  />

                  <Box mt={4} style={{ width: "100%" }}>
                    {isSubmitLoading ? (
                      <Loader />
                    ) : (
                      <Button
                        disabled={
                          !filterValues.parameters.length ||
                          !filterValues.structures.length ||
                          !filterValues.startDate ||
                          !filterValues.endDate ||
                          isExportLoading
                        }
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        onClick={() => handleSubmitFilters()}
                      >
                        Submit Filters
                      </Button>
                    )}
                  </Box>
                </form>
              </FormProvider>
            </AccordionDetails>
          </Box>
        </Accordion>
      )}
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="time-series"
          id="time-series"
        >
          <Typography variant="h4" ml={2}>
            Download Records
          </Typography>
        </AccordionSummary>
        <Box ml={3} mr={3} mb={3}>
          <AccordionDetails style={{ padding: 0 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={9}>
                <Box display="flex" alignItems="center" justifyContent="left">
                  <Typography mr={2}>Recordcount for Selected Data:</Typography>
                  <Typography variant="h6" align="left">
                    <Chip
                      variant="outlined"
                      color="primary"
                      label={filterValues.recordCount || 0}
                    />
                  </Typography>
                  {filterValues?.recordCount > 110000 && (
                    <Typography variant="subtitle2" color="error" ml={2}>
                      Maximum records for download is <strong>110,000</strong>
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                {isExportLoading ? (
                  <Loader />
                ) : (
                  <Button
                    disabled={
                      filterValues?.recordCount > 110000 || isSubmitLoading
                    }
                    variant="outlined"
                    color="primary"
                    fullWidth
                    size="large"
                    onClick={handleExportClick}
                  >
                    <Typography
                      variant="h6"
                      style={{ textTransform: "uppercase" }}
                    >
                      Download
                    </Typography>
                  </Button>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Box>
      </Accordion>
    </>
  );
};

export default withWidth()(QueryAndDownload);

import * as yup from "yup";

export const schema = yup
  .object({
    structure_group: yup.number().nullable(),
    structure_group_desc: yup.string().required(),
    notes: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    display_order: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
  })
  .required();

export const DEFAULT_STRUCTURE_GROUP_INFO_STATE = {
  structure_group: null,
  structure_group_desc: "",
  notes: "",
  display_order: "",
};

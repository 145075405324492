import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStateIfMounted } from "use-state-if-mounted";
import { useCreateLookupForTable } from "../../../hooks/useCreateLookupForTable";

export const useStructures = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedRecord, setSelectedRecord] = useStateIfMounted(null);

  const { data, isFetching, error, refetch } = useQuery(
    ["list-structures"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-structures`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const ownersLookup = useCreateLookupForTable({
    endpoint: "ui-list-owners",
    valueField: "owner_id",
    labelField: "owner_name",
  });

  const measSourcesLookup = useCreateLookupForTable({
    endpoint: "list-meas-sources",
    valueField: "meas_source",
    labelField: "description",
  });

  const structureTypesLookup = useCreateLookupForTable({
    endpoint: "list-structure-types",
    valueField: "structure_type",
    labelField: "description",
  });

  const columns = [
    {
      title: "Structure Id",
      field: "structure_ndx",
    },
    {
      title: "Owner Name",
      field: "owner_id",
      lookup: ownersLookup,
    },
    {
      title: "Station No",
      field: "station_no",
    },
    {
      title: "Well Id",
      field: "well_id",
    },
    {
      title: "Measurement Source",
      field: "meas_source",
      lookup: measSourcesLookup,
    },
    {
      title: "Structure Type",
      field: "structure_type",
      lookup: structureTypesLookup,
    },
    {
      title: "Permit Number",
      field: "permit_number",
    },
    {
      title: "Well Number",
      field: "well_number",
    },
    {
      title: "Township",
      field: "township",
    },
    {
      title: "Range",
      field: "range",
    },
    {
      title: "Section",
      field: "section",
    },
    {
      title: "Q40",
      field: "q40",
    },
    {
      title: "Location",
      field: "location",
    },
    {
      title: "Class D",
      field: "class_d",
    },
    {
      title: "Contract",
      field: "contract",
    },
    {
      title: "Analysis",
      field: "analysis",
    },
    {
      title: "Depth",
      field: "depth",
    },
    {
      title: "Depth Id",
      field: "depthid",
    },
    {
      title: "Datum Diff",
      field: "datumdiff",
    },
    {
      title: "Bedrock",
      field: "bedrock",
    },
    {
      title: "Altitude",
      field: "altitude",
    },
    {
      title: "Northing",
      field: "northing",
    },
    {
      title: "Easting",
      field: "easting",
    },
    {
      title: "Location Update",
      field: "location_update",
    },
    {
      title: "X Lon Nad83",
      field: "x_lon_nad83",
    },
    {
      title: "Y Lat Nad83",
      field: "y_lat_nad83",
    },
  ];

  return {
    selectedRecord,
    setSelectedRecord,
    data,
    isFetching,
    error,
    refetch,
    columns,
  };
};

import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Paper as MuiPaper,
  Typography as MuiTypography,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import MaterialTable from "material-table";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import axios from "axios";
import { useApp } from "../../../../AppProvider";
import { END_DATE, START_DATE } from "./constants";
import RecordDateFilterControl from "../../BaseComponents/RecordDateFilterControl";
import ConfirmDeleteDialog from "../../BaseComponents/ConfirmDeleteDialog";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Paper = styled(MuiPaper)(spacing);

const Instructions = styled.div`
  background-color: #edf6ff;
  border: 1px solid #c4e1ff;
  border-color: ${(props) => props.theme.palette.primary.main};
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 16px;
`;

const ContinuousDeleteRecords = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [dateRange, setDateRange] = useState({
    start_date: START_DATE,
    end_date: END_DATE,
  });
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const { doToast } = useApp();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [recordsToDelete, setRecordsToDelete] = useState([]);

  const { data, isLoading, refetch } = useQuery(
    ["delete-continuous-wq-data", dateRange],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/delete-continuous-wq-data`,
          {
            headers,
            params: {
              start_date: dateRange.start_date,
              end_date: dateRange.end_date,
            },
          }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const handleSubmit = (data) => {
    setDateRange(data);
  };

  const handleDelete = async (data) => {
    try {
      setIsFormSubmitting(true);

      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      await axios.delete(
        `${process.env.REACT_APP_ENDPOINT}/api/delete-continuous-wq-data`,
        {
          data,
          headers,
        }
      );

      setIsFormSubmitting(false);
      refetch();
      setIsDialogOpen(false);
      doToast("success", "Data successfully deleted");
    } catch (err) {
      setIsFormSubmitting(false);
      console.error(err);
      const message = err?.message ?? "Something went wrong";
      doToast("error", message);
    }
  };

  return (
    <>
      <Helmet title="Delete Water Quality Records" />
      <Typography variant="h3">
        Delete Continuous Water Quality Records
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Delete Continuous Water Quality Records</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Instructions>
        <Typography variant={"h4"}>Instructions</Typography>

        <p>Use this page to delete records for a selected date range</p>
        <ol>
          <li>
            Using the dropdowns, select a date range to retrieve records for and{" "}
            <strong>click submit.</strong>
          </li>
          <li>
            Check the box next to the records you would like to delete. If you
            would like to delete all records, simply click the checkbox next to
            the table headers.
          </li>
          <li>
            Scroll to the bottom of the screen and{" "}
            <strong>click submit.</strong>{" "}
          </li>
        </ol>
      </Instructions>

      <Paper p={6} mb={6}>
        <Typography variant="h4" gutterBottom>
          Filter Records to Delete
        </Typography>
        <Typography variant="body1" mb={6}>
          Use the filters below to select a date to retrieve records for. Then
          use the checkboxes to select the records you would like to delete.
        </Typography>
        <RecordDateFilterControl onSubmit={handleSubmit} />
      </Paper>
      <Paper p={6}>
        <Typography variant="h4" gutterBottom>
          Data Preview
        </Typography>
        <Typography variant="body1" gutterBottom>
          The following table matches the filters above. Check the boxes next to
          the records you would like to delete. If you would like to delete all
          records, simply click the checkbox next to the table headers.
        </Typography>
        <MaterialTable
          id="Delete Data Preview"
          title={`Delete Data Preview`}
          loading={isLoading}
          columns={[
            {
              title: "Location",
              field: "location_name",
            },
            {
              title: "Date",
              field: "sample_date",
              type: "date",
            },
            {
              title: "Time",
              field: "sample_time",
              type: "time",
            },
            {
              title: "Parameter",
              field: "parameter_name",
              cellStyle: {
                whiteSpace: "nowrap",
              },
            },
            {
              title: "Value",
              field: "result_value",
            },
            {
              title: "Units",
              field: "unit_name",
              cellStyle: {
                whiteSpace: "nowrap",
              },
            },
          ]}
          data={data}
          localization={{
            toolbar: { searchPlaceholder: "Data Selection" },
          }}
          components={{
            Container: (props) => <div {...props} />,
          }}
          actions={[
            {
              tooltip: "Delete All Selected Rows",
              icon: "delete",
              onClick: (evt, data) => {
                if (!isFormSubmitting) {
                  setIsDialogOpen(true);
                  setRecordsToDelete(data);
                }
              },
            },
          ]}
          options={{
            filtering: true,
            emptyRowsWhenPaging: false,
            showTitle: false,
            columnsButton: true,
            exportButton: true,
            exportAllData: true,
            addRowPosition: "first",
            pageSize: 25,
            pageSizeOptions: [25, 50, 100, 200],
            padding: "dense",
            searchFieldAlignment: "right",
            selection: true,
            minBodyHeight: "250px",
            maxBodyHeight: 800,
          }}
        />
        <ConfirmDeleteDialog
          open={isDialogOpen}
          onConfirm={() => {
            handleDelete(recordsToDelete);
          }}
          onClose={() => setIsDialogOpen(false)}
        />
      </Paper>
    </>
  );
};

export default ContinuousDeleteRecords;

import { Grid, Typography } from "@material-ui/core";
import React from "react";

import { FormSection, GridItem, SimpleTextField } from "../BaseComponents";

function MethodInfoForm() {
  return (
    <>
      <FormSection>
        <Typography variant="h5">Basic Info</Typography>

        <Grid container spacing={4}>
          <GridItem>
            <SimpleTextField
              name="method_desc_official"
              label="Method Name"
              required
            />
          </GridItem>
          <GridItem xs={12} lg={6} xl={6}>
            <SimpleTextField
              multiline
              rows={4}
              name="notes"
              variant="outlined"
              label="Notes"
            />
          </GridItem>
        </Grid>
      </FormSection>
    </>
  );
}

export default MethodInfoForm;

import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import { Autocomplete as MaterialAutocomplete } from "@material-ui/lab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListSubheader from "@material-ui/core/ListSubheader";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { VariableSizeList } from "react-window";
import { Controller } from "react-hook-form";

const LISTBOX_PADDING = 8;

const renderRow = (props) => {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          key={itemCount}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  listbox: {
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
}));

/**
 * This base autocomplete component is in part so complicated
 * beause of the need to virtualize the list of options.
 * For things like structures, there were just too many dropdown options
 * to display them in a normal autocomplete list
 *
 * @see https://v4.mui.com/components/autocomplete/#virtualization
 */
const BaseAutocomplete = React.forwardRef(function BaseAutocomplete(
  props,
  ref
) {
  const {
    name,
    label,
    options = [],
    multiple = false,
    value = multiple ? [] : null,
    onChange,
    ...other
  } = props;
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    const newEvent = { ...event, target: { ...event.target, name } };
    if (multiple) {
      const values = newValue.map((d) => d[0]);
      newEvent.target.value = values;
    } else {
      newEvent.target.value = newValue ? newValue[0] : null;
    }
    onChange(newEvent);
  };

  const setRenderOption = (option, selected) => {
    if (multiple) {
      return (
        <React.Fragment>
          <Checkbox
            style={{ marginRight: 8 }}
            color="primary"
            checked={selected}
          />
          {option[1]}
        </React.Fragment>
      );
    }
    return option[1];
  };

  return (
    <MaterialAutocomplete
      defaultValue={multiple ? [] : null}
      ref={ref}
      multiple={multiple}
      id={name}
      classes={{
        listbox: classes.listbox,
      }}
      ListboxComponent={ListboxComponent}
      options={options}
      disableCloseOnSelect={multiple}
      onChange={handleChange}
      getOptionLabel={(option) => {
        // hack workaround
        // sometimes option is an array, sometimes it is a number
        if (Array.isArray(option) && option.length > 0) {
          return option[1];
        }
        // If the option is not an array, attempt to find the corresponding label in the options list
        return options.find((o) => o[0] === option)?.[1] ?? "";
      }}
      value={
        multiple
          ? options?.filter((option) => value?.includes(option[0]))
          : value
      }
      renderOption={(option, { selected }) => setRenderOption(option, selected)}
      getOptionSelected={(option, value) =>
        multiple ? value.includes(option[0]) : option[0] === value
      }
      renderInput={(params) => (
        <TextField
          {...params}
          {...other}
          label={label}
          placeholder={label}
          fullWidth
        />
      )}
      style={{
        width: 250,
      }}
      {...other}
    />
  );
});

export const Autocomplete = (props) => {
  const { control, name, rules, ...rest } = props;
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => <BaseAutocomplete {...rest} {...field} />}
    />
  );
};

import * as yup from "yup";

yup.addMethod(yup.string, "convertEmpty", function () {
  return this.transform((value) => (value === "" ? null : value));
});

yup.addMethod(yup.number, "convertEmptyDropdown", function () {
  return this.transform((value) =>
    value === "-1" || value === -1 ? null : value
  );
});

export const schema = yup
  .object({
    parameter_ndx: yup.number().nullable(),
    parameter_code: yup.string().convertEmpty().nullable(),
    parameter_name: yup.string().required(),
    parameter_type_ndx: yup.number().convertEmptyDropdown(),
    param_speciation_text: yup.string().convertEmpty().nullable(),
    wellwatch_param: yup.boolean().nullable(),
    import_val_code: yup.string().convertEmpty().nullable(),
    import_flag_code: yup.string().convertEmpty().nullable(),
    param_units: yup.number().convertEmptyDropdown().nullable(),
    active: yup.boolean().nullable(),
    assoc_parameter_group_ndx: yup.array().of(yup.number()).nullable(),
    low_is_bad: yup.boolean().nullable(),
    analysis_include: yup.boolean().nullable(),
    core_param: yup.boolean().nullable(),
    map_param: yup.boolean().nullable(),
  })
  .required();

export const DEFAULT_PARAMETER_INFO_STATE = {
  parameter_ndx: null,
  parameter_code: "",
  parameter_name: "",
  parameter_type_ndx: "-1",
  param_speciation_text: "",
  wellwatch_param: false,
  import_val_code: "",
  import_flag_code: "",
  param_units: "-1",
  active: false,
  assoc_parameter_group_ndx: [],
  low_is_bad: false,
  analysis_include: false,
  core_param: false,
  map_param: false,
};

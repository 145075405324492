import React from "react";
import styled from "styled-components/macro";
import Button from "@material-ui/core/Button";
import {
  AccordionDetails,
  Chip as MuiChip,
  FormControl,
  Typography as MuiTypography,
  Box as MuiBox,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DATE_PICKER_FORMAT } from "./constants";

const Typography = styled(MuiTypography)(spacing);
const Box = styled(MuiBox)(spacing);

export const StyledFormControl = styled(FormControl)`
  width: 300px;
  margin-right: 12px;
  margin-bottom: 12px;
`;

export const Chip = styled(MuiChip)`
  ${spacing};
  margin-top: 1px;
  margin-bottom: 1px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 790;
  background-color: ${(props) => props.rgbcolor};
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
`;

export const DownloadButton = styled(Button)`
  height: 52px;
`;

export const AccordionTitle = styled(Typography).attrs({
  variant: "h4",
  ml: 3,
})``;

export const AccordionBox = styled(Box).attrs({
  mr: 3,
  mb: 3,
  ml: 3,
})``;

export const FilterSectionTitle = styled(Typography).attrs({
  variant: "h5",
  mt: 8,
  mb: 8,
})``;

export const DownloadButtonText = styled(Typography).attrs({
  variant: "h6",
})`
  text-transform: uppercase;
`;

export const DateTimeFormControl = React.memo(
  ({ id, label, value, onChange }) => (
    <StyledFormControl>
      <KeyboardDatePicker
        format={DATE_PICKER_FORMAT}
        inputVariant="outlined"
        autoOk
        id={id}
        label={label}
        value={value}
        onChange={onChange}
      />
    </StyledFormControl>
  )
);

export const TimePeriodSelection = ({
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
}) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <DateTimeFormControl
      id="start-date"
      label="Start Date"
      value={startDate}
      onChange={handleStartDateChange}
    />
    <DateTimeFormControl
      id="end-date"
      label="End Date"
      value={endDate}
      onChange={handleEndDateChange}
    />
  </MuiPickersUtilsProvider>
);

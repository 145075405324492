import * as yup from "yup";

export const schema = yup
  .object({
    method_ndx: yup.number().nullable(),
    method_desc_official: yup.string().required(),
    notes: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
  })
  .required();

export const DEFAULT_METHOD_INFO_STATE = {
  method_ndx: null,
  method_desc_official: "",
  notes: "",
};

import * as yup from "yup";

export const schema = yup
  .object({
    unit_ndx: yup.number().nullable(),
    unit_desc_official: yup.string().required(),
  })
  .required();

export const DEFAULT_UNIT_INFO_STATE = {
  unit_ndx: null,
  unit_desc_official: "",
};

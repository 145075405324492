import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";

import {
  SearchOutlined,
  AddOutlined as PlusIcon,
  ClearOutlined as RemoveIcon,
  RadioButtonUnchecked as UnselectedIcon,
  CheckCircle as SelectedIcon,
} from "@material-ui/icons";

import { matchSorter } from "match-sorter";

const DialogTitle = styled(MuiDialogTitle)`
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 8px;
  padding: 16px 24px;
`;

const DialogActions = styled(MuiDialogActions)`
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 8px 24px;
`;

export const RelationPickerItem = ({
  onRemove,
  displayField,
  relation,
  viewOnly = false,
  url,
}) => {
  return (
    <Box
      alignItems="center"
      justifyContent="space-between"
      display="flex"
      flexDirection="row"
      sx={{
        backgroundColor: "#edf6ff",
        border: "1px solid #1976d2",
        borderRadius: "4px",
        marginRight: "8px",
        marginBottom: "8px",
        p: 2,
        width: 250,
      }}
      component="article"
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          marginRight: "16px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <Link component={NavLink} exact to={url} target={"_blank"}>
          <Typography
            variant="body2"
            style={{
              color: "#1565c0",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            title={relation[displayField]}
          >
            {relation[displayField]}
          </Typography>
        </Link>
      </Box>

      {!viewOnly && (
        <RemoveIcon
          fontSize="small"
          onClick={() => onRemove()}
          style={{
            color: "#1976d2",
            cursor: "pointer",
          }}
        />
      )}
    </Box>
  );
};

export const RelationPickerOption = ({
  displayField,
  onSelect,
  option,
  selected,
}) => {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      style={{
        backgroundColor: selected ? "#edf6ff" : "#fff",
        border: "1px solid #ddd",
        borderColor: selected ? "#1976d2" : " #ddd",
        borderRadius: "4px",
        cursor: "pointer",
        marginBottom: "8px",
        padding: "8px",
        "&:hover": {
          borderColor: "primary.main",
        },
      }}
      component="article"
      onClick={() => onSelect(option)}
    >
      {selected ? (
        <SelectedIcon style={{ color: "#1976d2" }} />
      ) : (
        <UnselectedIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
      )}
      <Box display="flex" flexDirection="column" ml="16px">
        <Typography variant="body2" mb="8px">
          {option[displayField]}
        </Typography>
      </Box>
    </Box>
  );
};

export const RelationPicker = ({
  onAdd,
  onRemove,
  options,
  value,
  valueField,
  displayField,
  viewOnly = false,
  urlPrefix = "",
}) => {
  const [open, setOpen] = useState(false);
  const [pendingValue, setPendingValue] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSelect = (option) => {
    const isOptionSelected = pendingValue.some(
      (item) => item === option[valueField]
    );

    if (isOptionSelected) {
      setPendingValue((prev) =>
        prev.filter((item) => item !== option[valueField])
      );
    } else {
      setPendingValue((prev) => [...prev, option[valueField]]);
    }
  };

  const handleSearch = (event) => {
    const value = event?.target?.value;
    setSearchValue(value);
  };

  const handleAdd = () => {
    onAdd([...value, ...pendingValue]);
    setPendingValue([]);
    setSearchValue("");
    handleClose();
  };

  useEffect(() => {
    if (!!options && options?.length > 0) {
      const unselectedOptions = options.filter(
        (option) => !value.some((item) => item === option[valueField])
      );

      if (!!searchValue || searchValue !== "") {
        const newOptions = matchSorter(unselectedOptions, searchValue, {
          keys: [displayField],
        });
        setFilteredOptions(newOptions);
      } else {
        setFilteredOptions(unselectedOptions);
      }
    }
  }, [options, searchValue, value, displayField, valueField]);

  return (
    <>
      <Box display="flex" flexDirection="column">
        {!!value && value?.length > 0 && (
          <>
            {!viewOnly && (
              <div>
                <Button
                  color="primary"
                  disableElevation
                  startIcon={<PlusIcon />}
                  variant="contained"
                  onClick={handleOpen}
                >
                  Add New Relations
                </Button>
              </div>
            )}
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              mt={viewOnly ? 0 : 4}
            >
              {options
                .filter((option) => value.includes(option[valueField]))
                .map((option) => (
                  <RelationPickerItem
                    key={option[valueField]}
                    displayField={displayField}
                    relation={option}
                    onRemove={() => onRemove(option[valueField])}
                    viewOnly={viewOnly}
                    url={`${urlPrefix}/${option[valueField]}`}
                  />
                ))}
            </Box>
          </>
        )}
        {!value ||
          (value?.length === 0 && (
            <Box
              alignItems="center"
              bgcolor="#FFF3E0"
              border="1px solid #FFCC80"
              borderRadius="8px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              padding={6}
            >
              <Typography variant="body1" gutterBottom>
                No relations added
              </Typography>
              {!viewOnly && (
                <Button
                  color="primary"
                  disableElevation
                  startIcon={<PlusIcon />}
                  variant="contained"
                  onClick={handleOpen}
                >
                  Add New Relations
                </Button>
              )}
            </Box>
          ))}
      </Box>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minHeight: 550,
            height: 550,
          },
        }}
      >
        <DialogTitle>Add New Relations</DialogTitle>
        <DialogContent>
          <FormControl sx={{ mb: 2 }} variant="outlined" fullWidth>
            <OutlinedInput
              id="outlined-adornment-search"
              value={searchValue}
              onChange={handleSearch}
              startAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="search" edge="start">
                    <SearchOutlined />
                  </IconButton>
                </InputAdornment>
              }
              aria-describedby="outlined-search-helper-text"
              placeholder="Search"
              inputProps={{
                "aria-label": "search",
              }}
            />
          </FormControl>
          <Box display="flex" flexDirection="column" mt={4}>
            {filteredOptions?.map((option) => (
              <RelationPickerOption
                key={option[valueField]}
                displayField={displayField}
                option={option}
                onSelect={handleSelect}
                selected={pendingValue.some(
                  (item) => item === option[valueField]
                )}
              />
            ))}
            {filteredOptions?.length === 0 && (
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  textAlign: "center",
                  mt: 3,
                }}
              >
                No results found
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
            mb: 1,
          }}
        >
          <Typography variant="body2" color="textSecondary">
            {pendingValue?.length} relations selected
          </Typography>
          <div>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" onClick={handleAdd}>
              Add
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

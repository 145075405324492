import styled from "styled-components/macro";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import { Checkbox, Select, TextField } from "../../../components/inputs";
import Loader from "../../../components/Loader";
import React from "react";

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

export const AssocFormSection = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
  padding: 16px;
`;

export const GridItem = ({
  children,
  xs = 12,
  sm = 12,
  md = 6,
  lg = 6,
  xl = 3,
}) => {
  return (
    <Grid item xs={xs} sm={sm} md={md} leg={lg} xl={xl}>
      {children}
    </Grid>
  );
};

export const SimpleTextField = ({
  autoFocus,
  name,
  label,
  multiline,
  rows,
  type = "text",
  required = false,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <TextField
      autoFocus={autoFocus}
      control={control}
      multiline={multiline}
      rows={rows}
      variant="outlined"
      label={label}
      style={{
        width: "100%",
      }}
      name={name}
      error={errors[name]}
      type={type}
      required={required}
    />
  );
};

export const SimpleSelect = ({
  name,
  label,
  displayField,
  valueField,
  options,
  required,
  size,
  width = "100%",
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl
      variant="outlined"
      style={{ width }}
      error={errors[name]}
      size={size}
      required={required}
    >
      {!(options?.length > 0) ? (
        <Loader />
      ) : (
        <>
          <InputLabel id={name}>{label}</InputLabel>
          <Select
            control={control}
            labelId={name}
            id={name}
            label={label}
            name={name}
          >
            <MenuItem value="-1">None</MenuItem>
            {options.map((option) => (
              <MenuItem key={option[valueField]} value={option[valueField]}>
                {option[displayField]}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
      {!!errors[name] && <FormHelperText error>*Required field</FormHelperText>}
    </FormControl>
  );
};

export const SimpleCheckbox = ({ name, label }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Checkbox
          control={control}
          color="primary"
          error={errors[name]}
          name={name}
        />
      }
      label={label}
    />
  );
};

import { Grid, Typography } from "@material-ui/core";
import React from "react";

import { useFormContext } from "react-hook-form";

import {
  AssocFormSection,
  FormSection,
  GridItem,
  SimpleTextField,
  RelationPicker,
} from "../BaseComponents";

function StructureGroupInfoForm({ lookups }) {
  const { watch } = useFormContext();

  const watchStructures = watch("assoc_structure_ndx");

  return (
    <>
      <FormSection>
        <Typography variant="h5">Basic Info</Typography>

        <Grid container spacing={4}>
          <GridItem>
            <SimpleTextField
              name="structure_group_desc"
              label="Name"
              required
            />
          </GridItem>

          <GridItem>
            <SimpleTextField name="display_order" label="Display Order" />
          </GridItem>
        </Grid>
        <Grid container spacing={4}>
          <GridItem xs={12} lg={6} xl={6}>
            <SimpleTextField
              multiline
              rows={4}
              name="notes"
              variant="outlined"
              label="Notes"
            />
          </GridItem>
        </Grid>
      </FormSection>

      <Grid container spacing={4}>
        <Grid item md={6}>
          <AssocFormSection>
            <Typography variant="h5">Structures</Typography>
            <RelationPicker
              onAdd={() => {}}
              onRemove={() => {}}
              options={lookups.structures}
              displayField="structure_name"
              valueField="structure_ndx"
              value={watchStructures || []}
              viewOnly
              urlPrefix={"/data-management/structures"}
            />
          </AssocFormSection>
        </Grid>
      </Grid>
    </>
  );
}

export default StructureGroupInfoForm;

import { useQuery } from "react-query";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import {
  Accordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Divider as MuiDivider,
  Link,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import React from "react";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { useAuth0 } from "@auth0/auth0-react";
import PendingRecordsTable from "./PendingRecordsTable";
import { ExpandMore } from "@material-ui/icons";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Paper = styled(MuiPaper)(spacing);

const AccordionSummary = styled(MuiAccordionSummary)`
  border-bottom: 1px solid #ddd;
  padding: 0 24px;
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  display: block;
  padding: 0 24px 24px 24px;
  width: 100%;
`;

const Instructions = styled.div`
  background-color: #edf6ff;
  border: 1px solid ${(props) => props.theme.palette.primary.main}; /* Combine and use theme color here */
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 16px;
`;

const AddNewValueText = styled(Typography)`
  margin-bottom: 16px;
  margin-top: 16px;
  max-width: 768px;
`;

const AddNewValue = ({ type, to }) => {
  return (
    <AddNewValueText variant="body2" color="textSecondary">
      Can't find an existing value to map a pending value to? Navigate to the{" "}
      <Link component={NavLink} to={to}>
        {type} table page
      </Link>{" "}
      and add a new value and then return to this page.
    </AddNewValueText>
  );
};

const useFetch = (endpoint) => {
  const { getAccessTokenSilently } = useAuth0();

  const { data, isLoading, refetch } = useQuery(
    [endpoint],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  return {
    data,
    isLoading,
    refetch,
  };
};

const pollData = async (
  refetchFunction,
  conditionChecker = (data) => data.length === 0,
  maxAttempts = 18,
  interval = 5000
) => {
  return new Promise((resolve, reject) => {
    let attempts = 0;

    const executePoll = async () => {
      try {
        const { data } = await refetchFunction();
        if (conditionChecker(data)) {
          resolve();
        } else if (attempts < maxAttempts) {
          setTimeout(executePoll, interval);
          attempts += 1;
        } else {
          reject(new Error("Max polling attempts reached"));
        }
      } catch (error) {
        console.error("Polling error:", error);
        reject(error);
      }
    };

    executePoll();
  });
};

const BasicPendingRecords = () => {
  const { data: pendingLocations, refetch: refetchPendingLocations } = useFetch(
    "pending-structures-egrid/form"
  );
  const { data: verifiedLocations } = useFetch(
    "pending-structures-egrid/verified-options"
  );

  const { data: pendingParameters, refetch: refetchPendingParameters } =
    useFetch("pending-parameters-egrid/form");
  const { data: verifiedParameters } = useFetch(
    "pending-parameters-egrid/verified-options"
  );

  const { data: pendingUnits, refetch: refetchPendingUnits } = useFetch(
    "pending-units-egrid/form"
  );
  const { data: verifiedUnits } = useFetch(
    "pending-units-egrid/verified-options"
  );

  const { data: pendingMethods, refetch: refetchPendingMethods } = useFetch(
    "pending-methods-egrid/form"
  );
  const { data: verifiedMethods } = useFetch(
    "pending-methods-egrid/verified-options"
  );

  return (
    <>
      <Helmet title="Water Quality Data Import" />
      <Typography variant="h3">Pending Records Review</Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Pending Records Review</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Instructions>
        <Typography variant={"h4"}>Instructions</Typography>

        <p>
          Pending items are names for locations, parameters or units that have
          not yet been seen by this database.
        </p>

        <ul>
          <li>
            To import pending items: select the appropriate matching item from
            the "verified" drop-down menu, and click submit.
          </li>
          <li>
            If a form is blank, there are no pending items for that category.
          </li>
          <li>
            If there is no match, use the links in each section to add a new
            value to the relevant table.
          </li>
          <li>
            The submit process will take a little while to run as it is not only
            importing data but additionally re-building the data sources that
            power the Map Explorer and Query and Download Tools.
          </li>
        </ul>
        <Link component={NavLink} to={"/data-management/importers/basic"}>
          Return to Import Page
        </Link>
      </Instructions>

      <Paper p={0} mb={6}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h4" gutterBottom display="inline">
              Review Pending Locations
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AddNewValue
              type={"structures"}
              to={"/data-management/structures"}
            />
            <PendingRecordsTable
              data={pendingLocations}
              name={"locations"}
              handlePoll={() => pollData(refetchPendingLocations)}
              submitEndpoint={"pending-structures-egrid/form"}
              verifiedOptions={verifiedLocations}
            />
          </AccordionDetails>
        </Accordion>
      </Paper>
      <Paper p={0} mb={6}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h4" gutterBottom display="inline">
              Review Pending Parameters
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AddNewValue
              type={"parameters"}
              to={"/data-management/parameters"}
            />
            <PendingRecordsTable
              data={pendingParameters}
              name={"parameters"}
              handlePoll={() => pollData(refetchPendingParameters)}
              submitEndpoint={"pending-parameters-egrid/form"}
              verifiedOptions={verifiedParameters}
            />
          </AccordionDetails>
        </Accordion>
      </Paper>
      <Paper p={0} mb={6}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h4" gutterBottom display="inline">
              Review Pending Units
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AddNewValue type={"units"} to={"/data-management/units"} />
            <PendingRecordsTable
              data={pendingUnits}
              name={"units"}
              handlePoll={() => pollData(refetchPendingUnits)}
              submitEndpoint={"pending-units-egrid/form"}
              verifiedOptions={verifiedUnits}
            />
          </AccordionDetails>
        </Accordion>
      </Paper>
      <Paper p={0} mb={6}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h4" gutterBottom display="inline">
              Review Pending Methods
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AddNewValue type={"methods"} to={"/data-management/methods"} />
            <PendingRecordsTable
              data={pendingMethods}
              name={"methods"}
              handlePoll={() => pollData(refetchPendingMethods)}
              submitEndpoint={"pending-methods-egrid/form"}
              verifiedOptions={verifiedMethods}
            />
          </AccordionDetails>
        </Accordion>
      </Paper>
    </>
  );
};

export default BasicPendingRecords;

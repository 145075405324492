import { useMemo } from "react";
import useFetchData from "./useFetchData";

export const useCreateLookupForTable = ({
  endpoint,
  valueField,
  labelField,
}) => {
  const [data] = useFetchData(endpoint, [], true);

  const ownersLookupForTable = useMemo(() => {
    if (!data || !data?.length) return {};
    return data.reduce((acc, curr) => {
      acc[curr[valueField]] = curr[labelField];
      return acc;
    }, {});
  }, [data, valueField, labelField]);

  return ownersLookupForTable;
};

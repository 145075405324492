import * as yup from "yup";

export const schema = yup
  .object({
    owner_group_ndx: yup.number().nullable(),
    owner_group_desc: yup.string().required(),
    notes: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    display_order: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
  })
  .required();

export const DEFAULT_OWNER_GROUP_INFO_STATE = {
  owner_group_ndx: null,
  owner_group_desc: "",
  notes: "",
  display_order: "",
};

import * as yup from "yup";

export const SCHEMA = yup
  .object({
    parameterGroup: yup.number().required(),
    parameters: yup.array().of(yup.number()).nullable(),
    parameterSearch: yup.string().nullable(),
    structureGroup: yup.number().required(),
    structures: yup.array().of(yup.number()).nullable(),
    startDate: yup.date().nullable(),
    endDate: yup.date().nullable(),
  })
  .required();

export const DEFAULT_FILTER_VALUES = {
  parameterGroup: 2,
  parameters: [],
  parameterSearch: "",
  structureGroup: 14,
  structures: [],
  startDate: null,
  endDate: null,
};

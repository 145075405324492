import React from "react";
import { SimpleSelect } from "../../dataManagement/BaseComponents";
import { Search } from "../../../components/inputs";
import Button from "@material-ui/core/Button";
import { useState } from "react";
import styled from "styled-components/macro";
import Chips from "../../../components/Chips";
import { Typography } from "@material-ui/core";

const FilterRow = styled.div`
  display: flex;
  gap: 8px;
  margin: 16px 0 8px 0;
  flex-wrap: wrap;
`;

const FilterContainer = styled.div`
  width: 200px;
`;

const SelectionControlsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const ChipsContainer = styled.div`
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
`;

const FIELD_NAMES_BY_NAME = {
  parameterGroup: {
    valueField: "parameter_group_ndx",
    displayField: "parameter_group_desc",
  },
  parameters: {
    valueField: "parameter_ndx",
    displayField: "parameter_name",
  },
  structureGroup: {
    valueField: "structure_group",
    displayField: "structure_group_desc",
  },
  structures: {
    valueField: "structure_ndx",
    displayField: "structure_name",
  },
};

const CHIP_LIMIT = 25;

export const Filter = React.memo(
  ({
    filterName,
    filterOptions,
    filterValue,
    filterField,
    groupingLabel,
    groupingOptions,
    groupingName,
    searchName,
    searchLabel,
    onFilterChange,
    onSelectAll,
    onSelectNone,
  }) => {
    const [shouldShowAll, setShouldShowAll] = useState(false);

    const FILTER_FIELD_NAMES = FIELD_NAMES_BY_NAME[filterName];
    const GROUP_FIELD_NAMES = FIELD_NAMES_BY_NAME[groupingName];

    const handleShowAll = () => {
      setShouldShowAll(true);
    };

    const handleShowLess = () => {
      setShouldShowAll(false);
    };

    const handleSelectAll = (filterName, filterOptions, filterField) => {
      setShouldShowAll(true);
      onSelectAll(filterName, filterOptions, filterField);
    };

    return (
      <>
        <FilterRow>
          <FilterContainer>
            <SimpleSelect
              name={groupingName}
              label={groupingLabel}
              options={groupingOptions}
              displayField={GROUP_FIELD_NAMES.displayField}
              valueField={GROUP_FIELD_NAMES.valueField}
            />
          </FilterContainer>
          <Search
            name={searchName}
            label={searchLabel}
            variant="outlined"
            style={{
              flexGrow: 1,
              minWidth: 300,
            }}
          />
          <SelectionControlsContainer>
            <Button
              onClick={() =>
                handleSelectAll(filterName, filterOptions, filterField)
              }
              variant="outlined"
              color="secondary"
            >
              + All
            </Button>
            <Button
              onClick={() =>
                onSelectNone(filterName, filterOptions, filterField)
              }
              variant="outlined"
              color="secondary"
            >
              - None
            </Button>
          </SelectionControlsContainer>
        </FilterRow>
        <div>
          <ChipsContainer>
            {filterOptions?.length > 0 ? (
              <>
                <div>
                  <Chips
                    data={
                      shouldShowAll
                        ? filterOptions
                        : filterOptions.slice(0, CHIP_LIMIT)
                    }
                    valueField={FILTER_FIELD_NAMES.valueField}
                    displayField={FILTER_FIELD_NAMES.displayField}
                    handleChipClick={onFilterChange}
                    activeChips={filterValue}
                  />
                </div>
                {filterOptions?.length > CHIP_LIMIT && (
                  <div>
                    {shouldShowAll ? (
                      <Button variant="outlined" onClick={handleShowLess}>
                        - Show Less
                      </Button>
                    ) : (
                      <Button variant="outlined" onClick={handleShowAll}>
                        + Show All
                      </Button>
                    )}
                  </div>
                )}
              </>
            ) : (
              <Typography mt={6} align="center" variant="subtitle2">
                None Available
              </Typography>
            )}
          </ChipsContainer>
        </div>
      </>
    );
  }
);

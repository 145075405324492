import area from "@turf/area";
import length from "@turf/length";

export const handleCopyCoords = (value) => {
  const dummy = document.createElement("input");
  document.body.appendChild(dummy);
  dummy.value = value;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
};

export function updateArea(
  geojson,
  type,
  polygonRef,
  radiusRef,
  pointRef,
  lineRef,
  measurementsContainerRef,
  draw,
  setMeasurementsVisible
) {
  const data = draw.getAll();
  setMeasurementsVisible(true);

  const answerArea = polygonRef.current;
  const answerRadius = radiusRef.current;
  const answerPoint = pointRef.current;
  const answerLength = lineRef.current;

  if (geojson.geometry.type === "LineString" && type !== "draw.delete") {
    const exactLengthFeet = length(geojson, { units: "feet" });
    const roundedLength = exactLengthFeet.toFixed(2);
    answerLength.innerHTML = roundedLength + " ft";
  }

  if (geojson.properties.circleRadius && type !== "draw.delete") {
    const exactRadiusKm = geojson.properties.circleRadius;
    const exactRadiusFeet = exactRadiusKm * 3280.84;
    const roundedRadius = exactRadiusFeet.toFixed(2);
    answerRadius.innerHTML = roundedRadius + " ft";
  }

  if (geojson.geometry.type === "Point" && type !== "draw.delete") {
    answerPoint.innerHTML = `<strong>lat:</strong> ${geojson.geometry.coordinates[1].toFixed(
      5
    )} <br /><strong>long:</strong> ${geojson.geometry.coordinates[0].toFixed(
      5
    )}`;
  }

  if (
    data.features.filter((item) => item.geometry.type === "Point").length === 0
  ) {
    answerPoint.innerHTML = "--";
  }
  if (
    data.features.filter((item) => item.geometry.type === "LineString")
      .length === 0
  ) {
    answerLength.innerHTML = "--";
  }
  if (
    data.features.filter((item) => item.properties.circleRadius).length === 0
  ) {
    answerRadius.innerHTML = "--";
  }

  if (data.features.length > 0) {
    const exactAreaMeters = area(data);
    const exactAreaFeet = exactAreaMeters * 10.7639;
    const exactAreaAcre = exactAreaMeters / 4047;
    const roundedAreaFeet = exactAreaFeet.toFixed(2);
    const roundedAreaAcre = exactAreaAcre.toFixed(2);
    answerArea.innerHTML =
      roundedAreaAcre + " acres or " + roundedAreaFeet + " sq ft";
  } else {
    answerArea.innerHTML = "";
    answerRadius.innerHTML = "";
    answerPoint.innerHTML = "";
    answerLength.innerHTML = "";
    setMeasurementsVisible(false);
    // if (e.type !== "draw.delete") alert("Click the map to draw a polygon.");
  }
}

import React from "react";
import styled from "styled-components/macro";

import { Container, Grid } from "@material-ui/core";

import { BackgroundVideo } from "../../../components/BackgroundVideo";

const SiteTitle = styled.h1`
  font-size: 2.5rem;
  line-height: 1.2;
  text-align: left;
`;

const SiteTitleContainer = styled.header`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const Wrapper = styled.div`
  padding-top: 7rem;
  position: relative;
  text-align: center;
  overflow: hidden;
  height: 470px;

  color: ${(props) =>
    props.theme.palette.type === "dark"
      ? props.theme.palette.text.secondary
      : props.theme.palette.text.primary};
`;

const Content = styled.div`
  position: relative;
  padding: ${(props) => props.theme.spacing(6)}px 0;
  line-height: 150%;
`;

const BrandLogo = styled.img`
  vertical-align: middle;
  margin-bottom: 20px;
  width: 225px;
`;

function Hero() {
  return (
    <Wrapper>
      <Container>
        <Grid container alignItems="center" justify="center" spacing={4}>
          <Grid item xs={10} sm={9} md={8} lg={7}>
            <BackgroundVideo mp4={"/static/video/water.mp4"} />
            <Content>
              <SiteTitleContainer>
                <BrandLogo
                  src="/static/img/ccwcd_logo_large.webp"
                  alt="CCWCD Logo"
                />
                <SiteTitle>CCWCD Water Quality Dashboard</SiteTitle>
              </SiteTitleContainer>
            </Content>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default Hero;

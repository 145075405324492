import { format } from "date-fns";
import { titleize } from "inflected";
import { CSV_DATE_TIME_FORMAT } from "./raw/constants";

const formatDate = (value, dateFormat = CSV_DATE_TIME_FORMAT) =>
  format(new Date(value), dateFormat);

export const formatHeaderToTitleCase = (header) => {
  // replace underscores with spaces to handle snake_case
  let formattedHeader = header.replace(/_/g, " ");
  // insert a space before capital letters in camelCase strings, avoiding the first character
  formattedHeader = formattedHeader.replace(/([a-z])([A-Z])/g, "$1 $2");
  // apply titleize to convert to title case
  return titleize(formattedHeader);
};

export const convertToCSV = (data, excludeFields = []) => {
  if (!data.length) return "";

  // Extract headers and remove any that are in the excludeFields array
  const headers = Object.keys(data[0]).filter(
    (header) => !excludeFields.includes(header)
  );
  const formattedHeaders = headers.map((header) =>
    formatHeaderToTitleCase(header)
  );

  // Map each row of data to a CSV string, excluding specified fields
  const rows = data.map((row) =>
    headers
      .map((header) => {
        let value = row[header];

        if (header === "collect_timestamp") {
          value = formatDate(value); // Example format: January 1, 2020, 12:00 AM
        }
        // If value contains a comma, newline or double-quote, escape the entire string
        const needsEscaping = /[",\n]/.test(value);
        const escapedValue = needsEscaping
          ? `"${value.replace(/"/g, '""')}"`
          : value;
        return escapedValue;
      })
      .join(",")
  );

  // Combine headers and rows, each joined with commas and separated by newlines
  const csvContent = [formattedHeaders.join(","), ...rows].join("\n");
  return csvContent;
};

export const triggerDownload = (csvContent, filename = "download.csv") => {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const blobUrl = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const toggleValueInArray = (array, value) => {
  const set = new Set(array);
  if (set.has(value)) {
    set.delete(value);
  } else {
    set.add(value);
  }
  return Array.from(set);
};

export const createLookupMap = (filterOptions, filterField, filterIndex) => {
  return new Map(
    filterOptions?.map((option) => [option[filterIndex], option[filterField]])
  );
};

export const getNamesFromLookup = (selectedItems, lookupMap) =>
  selectedItems?.map((id) => lookupMap.get(id) || "Unknown").join(", ");

export const isDateValid = (dateInput) => {
  const date = new Date(dateInput);
  return Boolean(dateInput) && !isNaN(date.getTime());
};

import { Search } from "../../../components/inputs";
import Button from "@material-ui/core/Button";
import { useState } from "react";
import styled from "styled-components/macro";
import Chips from "../../../components/Chips";
import { Typography } from "@material-ui/core";

const FilterRow = styled.div`
  display: flex;
  gap: 8px;
  margin: 16px 0 8px 0;
  flex-wrap: wrap;
`;

const SelectionControlsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const ChipsContainer = styled.div`
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
`;

const FIELD_NAMES_BY_NAME = {
  parameters: {
    valueField: "parameter_ndx",
    displayField: "parameter_name",
  },
  structures: {
    valueField: "structure_ndx",
    displayField: "structure_name",
  },
};

const CHIP_LIMIT = 25;

export const Filter = ({
  filterName,
  filterOptions,
  filterValue,
  searchName,
  searchLabel,
  onFilterChange,
  onSelectAll,
  onSelectNone,
}) => {
  const [shouldShowAll, setShouldShowAll] = useState(false);

  const FILTER_FIELD_NAMES = FIELD_NAMES_BY_NAME[filterName];

  const handleShowAll = () => {
    setShouldShowAll(true);
  };

  const handleShowLess = () => {
    setShouldShowAll(false);
  };

  const handleSelectAll = () => {
    setShouldShowAll(true);
    onSelectAll();
  };

  return (
    <>
      <FilterRow>
        <Search
          name={searchName}
          label={searchLabel}
          variant={"outlined"}
          style={{
            flexGrow: 1,
            minWidth: 300,
            maxWidth: 500,
          }}
        />
        <SelectionControlsContainer>
          <Button
            onClick={handleSelectAll}
            variant={"outlined"}
            color={"secondary"}
          >
            + All
          </Button>
          <Button
            onClick={onSelectNone}
            variant={"outlined"}
            color={"secondary"}
          >
            - None
          </Button>
        </SelectionControlsContainer>
      </FilterRow>
      <div>
        <ChipsContainer>
          {filterOptions?.length > 0 ? (
            <>
              <div>
                <Chips
                  data={
                    shouldShowAll
                      ? filterOptions
                      : filterOptions.slice(0, CHIP_LIMIT)
                  }
                  valueField={FILTER_FIELD_NAMES.valueField}
                  displayField={FILTER_FIELD_NAMES.displayField}
                  handleChipClick={onFilterChange}
                  activeChips={filterValue}
                />
              </div>
              {filterOptions?.length > CHIP_LIMIT && (
                <div>
                  {shouldShowAll ? (
                    <Button variant={"outlined"} onClick={handleShowLess}>
                      - Show Less
                    </Button>
                  ) : (
                    <Button variant={"outlined"} onClick={handleShowAll}>
                      + Show All
                    </Button>
                  )}
                </div>
              )}
            </>
          ) : (
            <Typography mt={6} align="center" variant={"subtitle2"}>
              None Available
            </Typography>
          )}
        </ChipsContainer>
      </div>
    </>
  );
};

import { Controller } from "react-hook-form";
import { Checkbox as MuiCheckbox } from "@material-ui/core";

export const Checkbox = (props) => {
  const { control, name, ...rest } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <MuiCheckbox checked={field.value === true} {...rest} {...field} />
        );
      }}
    />
  );
};

import { useMutation, useQuery } from "react-query";
import { useApp } from "../../../AppProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useMemo, useState } from "react";
import axios from "axios";
import {
  convertToCSV,
  createLookupMap,
  getNamesFromLookup,
  isDateValid,
  toggleValueInArray,
  triggerDownload,
} from "../utils";
import { convertUTCDateToLocalDate } from "../../../utils";
import {
  BASE_URL,
  DEFAULT_FILTER_VALUES,
  DEFAULT_QUERY_OPTIONS,
  EXCLUDE_FIELDS,
  SCHEMA,
} from "./constants";
import useDebounce from "../../../hooks/useDebounce";

export const useAuthHeaders = () => {
  const { getAccessTokenSilently } = useAuth0();

  const getHeaders = async () => {
    try {
      const token = await getAccessTokenSilently();
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    } catch (error) {
      console.error("Error fetching access token:", error);
      return { headers: {} };
    }
  };

  return getHeaders;
};

export const useFetch = (endpoint, params, options = {}) => {
  const getHeaders = useAuthHeaders();

  const queryFn = async () => {
    const { headers } = await getHeaders();

    const { data } = await axios.get(`${BASE_URL}/${endpoint}`, {
      headers,
      params,
    });
    return data;
  };

  return useQuery([endpoint, params], queryFn, {
    onError: (error) => console.error(error),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });
};

const useFormFilters = () => {
  const [filterValues, setFilterValues] = useState(DEFAULT_FILTER_VALUES);

  const handleFilterValues = useCallback(
    (name, value) => {
      setFilterValues((prevState) => {
        if (["parameters", "structures"].includes(name)) {
          return {
            ...prevState,
            [name]: toggleValueInArray(prevState[name], value),
          };
        }
        return { ...prevState, [name]: value };
      });
    },
    [setFilterValues]
  );

  const onFilterSelectAll = useCallback(
    (filterType, filterOptions, filterField) => {
      setFilterValues((prevState) => {
        const selectedValues = filterOptions.map((x) => x[filterField]);
        const combinedValues = new Set([
          ...prevState[filterType],
          ...selectedValues,
        ]);
        return { ...prevState, [filterType]: [...combinedValues] };
      });
    },
    [setFilterValues]
  );

  const onFilterSelectNone = useCallback(
    (filterType, filterOptions, filterField) => {
      setFilterValues((prevState) => {
        const filteredValues = prevState[filterType].filter((prevValue) =>
          filterOptions.every((option) => option[filterField] !== prevValue)
        );
        return { ...prevState, [filterType]: filteredValues };
      });
    },
    [setFilterValues]
  );

  return {
    filterValues,
    setFilterValues,
    handleFilterValues,
    onFilterSelectAll,
    onFilterSelectNone,
  };
};

export const useQueryAndDownload = () => {
  const { doToast } = useApp();
  const getHeaders = useAuthHeaders();

  // filters and form
  const {
    filterValues,
    setFilterValues,
    handleFilterValues,
    onFilterSelectAll,
    onFilterSelectNone,
  } = useFormFilters();

  const methods = useForm({
    resolver: yupResolver(SCHEMA),
    defaultValues: DEFAULT_FILTER_VALUES,
  });

  const watch = methods.watch;
  const setValue = methods.setValue;

  const watchParameterGroup = watch("parameterGroup");
  const watchParameterSearch = watch("parameterSearch");
  const watchStructureGroup = watch("structureGroup");
  const watchStructureSearch = watch("structureSearch");

  const debouncedParameterSearch = useDebounce(watchParameterSearch, 500);
  const debouncedStructureSearch = useDebounce(watchStructureSearch, 500);

  // inputs data
  const { data: parameterGroups } = useFetch("list-parameter-groups");
  const { data: parameters } = useFetch("list-parameters", {
    parameterGroup: watchParameterGroup,
    parameterSearch: debouncedParameterSearch,
  });
  const { data: structureGroups } = useFetch("list-structure-groups");
  const { data: structures } = useFetch("list-structures", {
    structureGroup: watchStructureGroup,
    structureSearch: debouncedStructureSearch,
  });

  // initialize user filters
  useFetch(
    "query-and-download-raw/filters",
    {},
    { onSuccess: onSuccessUserSelections }
  );
  function onSuccessUserSelections(userSelections) {
    if (userSelections) {
      setFilterValues((prevState) => {
        return {
          ...prevState,
          parameters: userSelections.parameters,
          structures: userSelections.locations,
          startDate: convertUTCDateToLocalDate(
            new Date(userSelections.start_date)
          ),
          endDate: convertUTCDateToLocalDate(new Date(userSelections.end_date)),
        };
      });
      setValue("parameterGroup", userSelections.parameter_group_ndx);
      setValue("structureGroup", userSelections.location_group_ndx);
    }
  }

  // update record count
  const { refetch: refetchRecordCounts, isFetching: isLoadingRecordCounts } =
    useFetch(
      "query-and-download-raw/record-counts",
      {},
      {
        onSuccess: (data) => {
          handleFilterValues("recordCount", data?.sel_recordcount ?? 0);
        },
      }
    );

  // submit user filters
  const { mutate: handleSubmitFilters, isLoading: isLoadingSubmit } =
    useMutation(
      async () => {
        const { headers } = await getHeaders();

        await axios.put(
          `${BASE_URL}/query-and-download-raw/filters`,
          {
            parameters: filterValues.parameters,
            locations: filterValues.structures,
            start_date: filterValues.startDate,
            end_date: filterValues.endDate,
            parameter_group_ndx: watchParameterGroup,
            location_group_ndx: watchStructureGroup,
          },
          { headers }
        );
      },
      {
        onSuccess: async () => {
          await refetchRecordCounts();
          doToast("success", "New filters were applied to the database");
        },
        onError: (error) => {
          console.error(error);
          const message = error?.message ?? "Something went wrong";
          doToast("error", message);
        },
      }
    );

  // export data
  const parameterLookupMap = useMemo(() => {
    return createLookupMap(parameters, "parameter_name", "parameter_ndx");
  }, [parameters]);

  const structureLookupMap = useMemo(() => {
    return createLookupMap(structures, "structure_name", "structure_ndx");
  }, [structures]);

  const { mutate: handleExportClick, isLoading: isLoadingExport } = useMutation(
    async (filename) => {
      const { headers } = await getHeaders();
      const url = `${BASE_URL}/query-and-download-raw/data`;

      let { data } = await axios.get(url, { headers });

      const selectedParameterNames = getNamesFromLookup(
        filterValues.parameters,
        parameterLookupMap
      );
      const selectedStructureNames = getNamesFromLookup(
        filterValues.structures,
        structureLookupMap
      );

      return {
        csvContent: [
          `"Results for constituents: ${selectedParameterNames}"`,
          `"Results for locations: ${selectedStructureNames}"`,
          convertToCSV(data, EXCLUDE_FIELDS),
        ].join("\n"),
        filename,
      };
    },
    {
      onSuccess: ({ csvContent, filename }) => {
        triggerDownload(csvContent, filename);
        doToast("success", "CSV file successfully added to download folder.");
      },
      onError: (err) => {
        doToast("error", "Failed to download CSV file.");
        console.error(err);
      },
    }
  );

  // state indicators
  const isLoading = useMemo(() => {
    return isLoadingSubmit || isLoadingRecordCounts || isLoadingExport;
  }, [isLoadingSubmit, isLoadingRecordCounts, isLoadingExport]);

  const isSubmitValid =
    !!filterValues.parameters.length &&
    !!filterValues.structures.length &&
    isDateValid(filterValues.startDate) &&
    isDateValid(filterValues.endDate) &&
    !isLoading;

  const isDownloadValid =
    filterValues.recordCount > 0 &&
    filterValues.recordCount <= 110000 &&
    !isLoading;

  return {
    // filters and form
    filterValues,
    handleFilterValues,
    onFilterSelectAll,
    onFilterSelectNone,
    methods,

    // input Options
    parameterGroups,
    parameters,
    structureGroups,
    structures,

    // actions
    handleSubmitFilters,
    handleExportClick,

    // state indicators
    isLoadingExport,
    isSubmitValid,
    isDownloadValid,
  };
};

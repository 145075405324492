import React, { useState, useEffect } from "react";
import { Typography, Grid, Box, Chip } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import useFetchData from "../../../hooks/useFetchData";

import SearchableList from "../../../components/SearchableList";

import styled from "styled-components/macro";
import { Flex } from "../../../components/Flex";
import AssociationControls from "./AssociationControls";
import StructuresListForUserManagementTable from "./StructuresListForUserManagementTable";
import useFormSubmitStatus from "../../../hooks/useFormSubmitStatus";
import axios from "axios";
import FormSnackbar from "../../../components/FormSnackbar";

const Root = styled.div`
  margin-top: 6px;
  width: 100%;
`;

const UsersToStructuresAssoc = () => {
  const { setWaitingState, snackbarOpen, snackbarError, handleSnackbarClose } =
    useFormSubmitStatus();
  const { getAccessTokenSilently } = useAuth0();

  const [refreshSwitch, setRefreshSwitch] = useState(false);
  const [activeUser, setActiveUser] = useState({});
  const [associatedStructures, setAssociatedStructures] = useState([]);

  const [Structures] = useFetchData("list-structures", [refreshSwitch]);
  const [Users] = useFetchData(`users/associated-structures`, []);

  // Set the active user to the first user in the list
  useEffect(() => {
    if (Users.length > 0) {
      setActiveUser(Users[0]);
      setAssociatedStructures(Users[0].assoc_structure_ndx);
    }
  }, [Users]);

  const handleUserSelect = (user) => {
    setActiveUser(user);
    setAssociatedStructures(user.assoc_structure_ndx);
  };

  const handleRefresh = () => {
    setRefreshSwitch((state) => !state);
  };

  const handleSubmit = () => {
    // Set up a cancellation source
    let didCancel = false;
    setWaitingState("in progress");
    async function writeData() {
      try {
        const token = await getAccessTokenSilently();

        // Create request headers with token authorization
        const headers = { Authorization: `Bearer ${token}` };

        await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/users/associate-structures`,
          {
            data: {
              user_id: activeUser.user_id,
              assoc_structure_ndx: associatedStructures,
            },
          },
          { headers }
        );
        if (!didCancel) {
          // Ignore if we started fetching something else
          setWaitingState("complete", "no error");
          handleRefresh();
        }
      } catch (err) {
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
        } else {
          console.error(err);
          setWaitingState("complete", "error");
        }
        didCancel = true;
      }
    }
    writeData();
  };

  const handleSelectNone = () => setAssociatedStructures([]);

  const handleSelectAll = () =>
    setAssociatedStructures(Structures.map((d) => d.structure_ndx));

  const handleStructureSelect = (rowData, row) => {
    const value = row;
    const checked = row.tableData.checked;
    setAssociatedStructures((prevState) => {
      let newValues = [...prevState];
      if (checked) {
        newValues.push(value.structure_ndx);
      } else {
        const index = newValues
          .map((value) => value)
          .indexOf(value.structure_ndx);
        newValues.splice(index, 1);
      }
      return newValues;
    });
  };

  return (
    <Root>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <SearchableList
            title="Users"
            data={Users}
            valueField="user_id"
            primaryDisplayField="email"
            active={activeUser}
            onClick={handleUserSelect}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box marginTop={2} width="100%">
            <Flex>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                Manage Structure Associations:
              </Typography>

              {activeUser && (
                <Box marginTop={2} marginBottom={2} marginLeft={2}>
                  <Chip label={activeUser.email} />
                </Box>
              )}
            </Flex>

            {activeUser && (
              <AssociationControls
                handleSave={handleSubmit}
                handleSelectAll={handleSelectAll}
                handleSelectNone={handleSelectNone}
              />
            )}

            {activeUser ? (
              <StructuresListForUserManagementTable
                selections={associatedStructures}
                onCheck={handleStructureSelect}
                refreshSwitch={refreshSwitch}
              />
            ) : (
              <>
                <Typography variant="body1" paragraph>
                  Select a user from the left to associate them with structures.
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <FormSnackbar
        open={snackbarOpen}
        error={snackbarError}
        handleClose={handleSnackbarClose}
        successMessage="Associations successfully saved."
        errorMessage="Associations could not be saved."
      />
    </Root>
  );
};

export default UsersToStructuresAssoc;
